import React from "react";
import { IoLogoFacebook } from "react-icons/io5";

function Contact() {
    return (
        <>
            <h1>Kapcsolat</h1>

            Romániai Magyar Dalosszövetség<br/>
            UNIUNEA FORMATIILOR MUZICALE MAGHIARE DIN ROMANIA<br/>

            <h4 style={{marginBottom: '2px'}}>Adószám</h4>
            14403062

            <h4 style={{marginBottom: '-15px'}}>Cím</h4>
            <p>400015 Kolozsvár, Republicii u. 5/1</p>
            
            <h4 style={{marginBottom: '-15px'}}>Telefonszám</h4>
            <p>0740881574 − Tóth Guttman Emese</p>
            
            <h4 style={{marginBottom: '-15px'}}>E-mail</h4>
            <p>dalosszovetseg@yahoo.com</p>  

            <h4 style={{marginBottom: '-15px'}}>Közösségi média oldalak</h4>
            <p>
                <a href="https://www.facebook.com/dalosszovetseg.ro" target="_blank">
                    <IoLogoFacebook style={{fontSize: '30px', color: '#006633'}}/>
                </a>
            </p>

            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2732.8945202716295!2d23.58448347670792!3d46.766975545578994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47490e828f44f137%3A0xecabbea4b03bcf68!2sStr.%20Republicii%205%2C%20Cluj-Napoca%20400347%2C%20Romania!5e0!3m2!1sen!2sde!4v1699564039818!5m2!1sen!2sde" 
                // width="800" 
                // height="600" 
                style={{border: '0', width: '100%', height: '400px'}}
                allowfullscreen="yes" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </>
    );
}

export default Contact;