import React, {useState} from "react";
import { initialLinks } from "../../../util/initialFormData";
import FormInput from "../../forms/elementary/FormInput";
import { TiSocialYoutube, TiSocialFacebook, TiSocialInstagram } from 'react-icons/ti';

function FormationPageLinks(props) {
    const {links, linkChange} = props;
    // const [links, setLinks] = useState(initialLinks);

    const inputLinks = [
        {
            id: "ownLink",
            name: "ownLink",
            type: "text",
            placeholder: "Saját weboldal",
            required: false,
        },
        {
            id: "youtubeLink",
            name: "youtubeLink",
            type: "text",
            placeholder: "youtube.com",
            required: false,
        },
        {
            id: "facebookLink",
            name: "facebookLink",
            type: "text",
            placeholder: "facebook.com",
            required: false,
        },
        {
            id: "instagramLink",
            name: "instagramLink",
            type: "text",
            placeholder: "instagram.com",
            required: false,
        },
    ];

    {/* 
        TODO: icons for links
        <TiSocialYoutube/>
        <TiSocialFacebook/>
        <TiSocialInstagram/>
        <HiLink/> 
    */}

    return (
        <>
            {inputLinks.map((input) => (
                <FormInput
                key={input.id}
                {...input}
                value={links[input.name]}
                onChange={linkChange}
                />
            ))}
        </>
    );
};



export default FormationPageLinks;