import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../../forms/elementary/FormInput";
import TextEditor from "../../forms/elementary/TextEditor";

import { IoIosAddCircleOutline } from "react-icons/io";

import { AiOutlineDelete } from "react-icons/ai";
import { initialEventData } from "../../../util/initialFormData";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import FormInputUncontrolled from "../../forms/elementary/FormInputUncontrolled";
import AddressAutocompleteCopy from "../../forms/elementary/AddressAutocompleteCopy";
import { updateOrInsertEventData, uploadEventFiles } from "../../../service/event";
import toast from "react-hot-toast";

export default function NewEvent(props) {
    const nav = useNavigate();
    const [text, setText] = useState('');
    const [coverImg, setCoverImg] = useState(null);
    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
      const selectedFiles = Array.from(event.target.files);
      setFiles(selectedFiles);
    };

    const {reset, control, ...methods} = useForm({
      mode: 'onBlur',
      reValidateMode: 'onBlur'
      // defaultValues: {...initialFormationData, ...initialOfficialNames, ...initialAddress}
    });
    const { fields, append, remove } = useFieldArray({
      name: "links",
      control: control,
      defaultValue: [{ link: "" }],
    });

    const input_fields = [
      {
          id: 'eventTitle',
          name: "title",
          type: "text",
          // errorMessage: "Hibás cím formátum!",
          label: "Cím",
          cls: "full-width-group",
          options: {
            required: "A cím megadása kötelező!",
            // validate: (value) => {
            //     if (!/^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$/.test(value))
            //       return "Hibás vezetéknév formátum!";
            //     return true;
            //   },
    
          },
          required: true,
      },
      {
        id: 'eventStartDate',
        name: "startDate",
        type: "datetime-local",
        // errorMessage: "Hibás cím formátum!",
        label: "Kezdés ideje",
        cls: "none",
        options: {
          required: "A kezdeti dátum megadása kötelező!",
          // validate: (value) => {
          //     if (!/^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$/.test(value))
          //       return "Hibás vezetéknév formátum!";
          //     return true;
          //   },
  
        },
        required: true,
      },
      {
        id: 'eventEndDate',
        name: "endDate",
        type: "datetime-local",
        label: "Befejezés ideje",
        cls: "none",
        required: false,
      },
    ];

    async function testSubmit(data) {
      // console.log(data);
      // console.log(text);
      // console.log(files);

      const toSend = {...data, text};
      const respSentEvent = await updateOrInsertEventData(toSend);
      if (respSentEvent.state == 'SUCCESS') {
        toast.success('Az új eseményt sikeresen mentettük!');
        nav('/esemenyek');
      } else
        toast.error('Hiba lépett fel az esemény létrehozása során!');

      // file upload

      // if (coverImg || files.length > 0) {
      //   const formData = new FormData();
      //   if (coverImg)
      //     formData.append("myFiles", coverImg);
      //   if (files.length > 0) {
      //     files.map(item => {
      //       formData.append("myFiles", item);
      //     });
      //   }

      //   // TODO: change event ID
      //   const respUploadEventFiles = await uploadEventFiles(formData, 1);
      //   console.log(respUploadEventFiles);
      //   }

      // console.log(coverImg);
      // console.log(files);
    }

    return (
      <>
        <h1>Új esemény létrehozása</h1>

        <FormProvider {...methods}>

        <form className="user-data-page" onSubmit={methods.handleSubmit(testSubmit)}>
          {input_fields.map((input) => (
            <div className={input.cls}>
            <FormInputUncontrolled
              key={input.id}
              {...input}
            />
            </div>
          ))}

          <div className="full-width-group">
            <h2>Helyszín</h2>
            <AddressAutocompleteCopy entity="Event"/>
            {/* TODO: szervezők */}

            <h2>Leírás</h2>
            <TextEditor getText={setText} text={text}/>

          <h2>Linkek</h2>
          {/* TODO: kattintásra új formablak jelenik meg link hozzáadásához */}
          <button onClick={(e) => {
            e.preventDefault();
            append({ link: "" }); }}>Új link megadása</button>
          {/* Új link megadása <IoIosAddCircleOutline style={{fontSize: '20px'}}/> */}
          {/* <input name={`emails[${index}].email`} ref={methods.register}/> */}

          {
            fields.length > 0 ?
              fields.map((field, index) => (
                <>
                    <FormInputUncontrolled
                      key={field.id}
                      name={`links.${index}.link`}
                      type="text"
                      label=""
                      cls="full-width-group"
                      // options={{required: "Adja meg a linket vagy törölje a mezőt!"}}
                    />
                  <AiOutlineDelete onClick={() => {
                    // alert(`${index} deleted!`);
                    remove(index);
                    }} style={{fontSize: '20px', cursor: "pointer"}}/>
                </>
            )) : null
         }

          <h2>Dokumentumok</h2>
          {/* TODO: borítókép, random file + megnevezés */}
          {/* fájlok: borítókép és egyéb dokumentumok */}

          {/* <FormInputUncontrolled
            id="coverImg"
            key="coverImg"
            name="coverImg"
            type="file"
            label="Borítókép"
            cls="none"
          /> */}

          <div className="formInput">
            <label htmlFor="cover-input">Borítókép</label>
            <input id="cover-input" type="file" onChange={(e) => setCoverImg(e.target.files[0])}/> 
          </div>

          <div className="formInput">
          <label htmlFor="file-input">Egyebek</label>
          <input
            id="file-input"
            type="file"
            multiple
            onChange={handleFileChange}
          />
        {files.length > 0 ?
          <table>
            <thead>
              <tr>
                <th>Fájl neve</th>
                <th>Megnevezés</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => (
                <tr key={index}>
                  <td>{file.name}</td>
                  <td>
                    <input type="text" key={`fileTxt_${index}`}/>
                  </td>
                  <td>
                    <AiOutlineDelete onClick={() => {
                      alert(`${index} deleted!`);
                    }} style={{fontSize: '20px', cursor: "pointer"}}/>
                  </td>
                </tr>
                
              ))}
            </tbody>
          </table> : null
          }

          </div>

          {/* <div className="formInput">
            <label htmlFor="otherMedia">
              Egyebek
            </label>
            <input
              type="file"
              id="otherMedia"
              name="otherMedia"
              // onChange={(e) => setFile(e.target.files[0])}
            />
          </div> */}
        
            <button className='btn submit' style={{width:'100%', marginTop:'20px'}}>Létrehozás</button>
          </div>
        </form>
        </FormProvider>
      </>
    );
}