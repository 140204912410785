import React, { useEffect, useState } from "react";
import "./FormInput.css";
import { useFormContext } from "react-hook-form";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

function FormInputUncontrolled(props) {
  const { label, required, id, name, options, type, ...inputProps } = props;
  const { 
    register,
    formState: { errors } } = useFormContext();

  const [showPwd, setShowPwd] = useState(false);
  const toggleShowPassword = () => {
    setShowPwd(!showPwd);
  };

  return (
    <div className="formInput">
        <label htmlFor={name}>
            {label}{(required) ? <span style={{color:'red'}}>*</span> : null}
        </label>

    {
      (type != "password") ?
        <input
            className={errors[name] && "error"}
            {...register(name, options)}
            {...inputProps}
            type={type}
            id={id}
            name={name}
        /> :
        <>
          {
          // (inputProps.value != "") ?
            <span className="icons" onClick={toggleShowPassword}>
              { showPwd ? 
                <IoMdEye style={{color: "#18ab42"}}/> : 
                <IoMdEyeOff style={{color: "grey"}}/>
              }
            </span>
          }
          <input
            className={errors[name] && "error"}
            {...register(name, options)}
            {...inputProps}
            type={showPwd ? "text" : "password"}
            id={id}
            name={name}
          />
        </>
    }


        {
          errors[name] &&
          <span className="form-input-state">
            { errors[name].message }
          </span>
        }
    </div>
  );
};

export default FormInputUncontrolled;