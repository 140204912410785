import React, { useState, useEffect, useContext } from 'react';
import TextEditor from '../../forms/elementary/TextEditor';
import { initialLinks } from '../../../util/initialFormData';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { uploadFormationImg } from '../../../service/file';
import AuthContext from '../../../context/AuthProvider';
import { updateFormationDescription, updateOrInsertFormationLink, getFormationProfileData, sendFormationProfileToValidate } from '../../../service/formation';
import "./FormationPageUpdate.css";
import toast from 'react-hot-toast';
import FormationPageLinks from './FormationPageLinks';
import FormationPageImgs from './FormationPageImgs';
import '../../forms/Forms.css';
import { AiOutlineInfoCircle } from "react-icons/ai";
import "../elementary/InfoBox.css";

function FormationPageUpdate(props) {
    const { auth } = useContext(AuthContext);
    const [text, setText] = useState('');
    const [newFile, setNewFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [links, setLinks] = useState(initialLinks);


    // notifications
    const notify = (textParam) =>
        toast.success(textParam);

    useEffect(async () => {

        const getProfileData = await getFormationProfileData(auth.formationId);

        if (getProfileData.state === "SUCCESS") {
            // console.log(getProfileData.mess.formationDescription[0].text);
            // console.log(getProfileData.mess);
            // console.log(getProfileData.mess.formationLinks);

            setText(getProfileData.mess.formationNameAndDescription[0]?.text);
            getProfileData.mess.formationLinks.map(oneLink => {
                // console.log(oneLink);
                var linkType = oneLink.wtype_name.toLowerCase();
                    setLinks((prevLinks) => ({
                        ...prevLinks,
                        [linkType + 'Link']: (linkType !== "own" ? 'https://www.' + linkType + '.com/' + oneLink.url_website : oneLink.url_website) 
                    }));
                }
            );

            setFiles(getProfileData.mess.formationImgs);
            // console.log(getProfileData.mess.formationImgs);
        } else {
            toast.error("Az adatok betöltése sikertelen.\nKérjük vegye fel a kapcsolatot a rendszergazdával!");
        }

    }, []);

    // useEffect(() => {
    //     console.log(links);
    // }, [links]);

    const linkChange = (e) => {
        setLinks({...links, [e.target.name]: e.target.value});
    };

    const nav = useNavigate();

    const handleCancel = (e) => {
        e.preventDefault();
        nav('/egyuttesek');
    }

    const sendToValidate = async () => {
        const sentToValidate = await sendFormationProfileToValidate(auth.formationId, 0);
        if (sentToValidate.state == 'SUCCESS') {
            toast.success("Az adatlapot elküldtük ellenőrzésre!");
        } else 
            toast.error("Hiba történt az adatlap elküldése során.");
    };

    // TODO: data valiadation
    const handleSave = async (e) => {
        e.preventDefault();

        // image upload
        // TODO: put the new img to the list
        // TODO: multiple file upload

        var respUpload = {};

        // check if newFile is not empty
        if (newFile) {
            const formData = new FormData();
            formData.append("myFile", newFile);

            respUpload = await uploadFormationImg(formData, auth.formationId);

            if (respUpload.state != 'SUCCESS') {
                toast.error("Hiba lépett fel a képfeltöltés során.")
            }
            console.log(respUpload);
        } else {
            respUpload.state = 'SUCCESS';
        }

        // description & links

        const data = {'formationId': auth.formationId, 'text': text};
        const respDescriptionUpdate = await updateFormationDescription(data);

        if (respDescriptionUpdate.state != 'SUCCESS') {
                toast.error("Hiba lépett fel a bemutatkozó szöveg mentése során.")
        }
        // console.log(respDescriptionUpdate);


        const data2 = {'formationId': auth.formationId, 'links': links};
        const respFormationLinks = await updateOrInsertFormationLink(data2);

        if (respFormationLinks.state != 'SUCCESS') {
            toast.error("Hiba lépett fel a linkek mentése során.")
        }

        // console.log(respFormationLinks);

        if (respDescriptionUpdate.state == 'SUCCESS' && respFormationLinks.state == 'SUCCESS' && respUpload.state == 'SUCCESS'){
            // nav('/egyuttesek');
            toast.success("Az adatokat sikeresen mentettük!");
        }

        const sentToValidate = await sendFormationProfileToValidate(auth.formationId, -1);
      };

      const InfoBox = () => {
        return (
          <div className='info-container'>
            <div className='info-content'>
              <AiOutlineInfoCircle style={{fontSize: '30px', color:'grey'}}/>
              <div className='info-text'>
                <p>Adatlapja jelenleg nem publikus.</p>
                <p>Az adatok mentése után a <b>Küldés</b> gombra kattintva küldheti el adatlapját ellenőrzésre.</p>
              </div>
            </div>
          </div>
        );
      }

    return (
        
        // bemutatkozás (text editor)
        // csoportkép (eformidable képfeltöltés) vagy logó
        // elérhetőség (?)
        // break to sections
        <>
        <h1>Adatlap szerkesztése</h1>

            {/* <InfoBox/> */}

            <h2>Bemutatkozás</h2>
            <TextEditor getText={setText} text={text}/>
            
            <h2>Profilkép</h2>
                {/* Fekvő kép */}
                {/* TODO: image editor */}
                {/* TODO: upload multiple files at once: https://www.youtube.com/watch?v=ijx0Uqlo3NA */}
            
            {
                files.length == 0 ?
                <input
                    // style={{ display: "none" }}
                    type="file"
                    onChange={(e) => setNewFile(e.target.files[0])}
                /> : null
            }
{/* 
            //TODO: biztos törli a kiválasztott képet? */}
            <FormationPageImgs files={files} onChangeNotify={setFiles}/>

            <h2>Linkek</h2>
            <FormationPageLinks links={links} linkChange={linkChange}/>


            {/* // TODO: egyéb (opcionális -> pl. karnagy szekció) (?) */}


            <button className='btn secondary' onClick={handleCancel}>Mégsem</button>
            <button className='btn primary' onClick={handleSave}>Mentés</button>

           {/* TODO: send the profile to validation */}
            <button className='btn submit' onClick={sendToValidate}>Küldés ellenőrzésre</button>

            {/* <button onClick={() => notify("Igeeeen!")}>Toast!</button>
            <button onClick={() => toast.dismiss()}>Dismiss</button> */}
        </>
    );
}

export default (props) => (
    <FormationPageUpdate
        {...props}
        params={useParams()}
    />
  );