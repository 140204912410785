import React, {useEffect, useState} from 'react';

import TextEditor from '../../forms/elementary/TextEditor';
import toast from 'react-hot-toast';

import { getNVFormations } from '../../../service/formation';
import FormationPageImgs from './FormationPageImgs';
import FormationPageLinks from './FormationPageLinks';

import { uploadFormationImg } from '../../../service/file';
import { updateFormationDescription, updateOrInsertFormationLink, updateFormationProfileState } from '../../../service/formation';

export default function FormationPageManagement(props) {
  const [notVerifFormations, setNotVerifFormations] = useState([]);
  const [showMore, setShowMore] = useState({});
  const [newFile, setNewFile] = useState(null);
  
  // TODO: that's what (s)he said> https://www.robinwieruch.de/react-hooks-fetch-data/
  useEffect(async () => {
    const fetchData = async () => {
      const getFormations = await getNVFormations();
      // console.log(getFormations);

      if (getFormations.state === "SUCCESS") {
        // setNotVerifFormations(getFormations.mess);
        const transformedMess = getFormations.mess.map(item => {

        let newLinks = item.links.reduce((acc, oneLink) => {
            let linkType = oneLink.wtype_name.toLowerCase();
            let linkValue = linkType !== "own" ? 'https://www.' + linkType + '.com/' + oneLink.url_website : oneLink.url_website;
            acc[linkType + 'Link'] = linkValue;
            return acc;
        }, {});

          return {
            ...item,
            links: newLinks
          };
        });

        setNotVerifFormations(transformedMess);
      } else {
        toast.error("Az adatok betöltése sikertelen.");
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const initialShowMoreState = {};
    notVerifFormations.forEach(parFormation => {
      initialShowMoreState[parFormation.id_formation] = false;
    })
    setShowMore(initialShowMoreState);
  }, []);

  // useEffect(() => {
  //   console.log("State changed!");
  //   console.log(showMore);
  // }, [showMore]);

  // useEffect(() => {
  //   console.log(notVerifFormations);
  // }, [notVerifFormations]);

  const handleClick = (fId) => {
    // console.log(fId);
    setShowMore(prevState => ({
        ...prevState,
        [fId]: !prevState[fId]
    }));
};

  const onTextChange = (e, formationId) => {
    setNotVerifFormations(prevNotVerifFormations =>
      prevNotVerifFormations.map(item => {
      if (item.id_formation == formationId) {
        return {...item, description_formation: e};
      } else {
        return item;
      }
    }));
  };

  const onLinkChange = (e, formationId) => {
    setNotVerifFormations(prevNotVerifFormations =>
        prevNotVerifFormations.map(item => {
            if (item.id_formation === formationId) {
                return {
                    ...item,
                    links: {
                        ...item.links,
                        [e.target.name]: e.target.value
                    }
                };
            } else {
                return item;
            }
        })
    );
  };

  const onImgChange = (newImgs, formationId) => {
    setNotVerifFormations(prevNotVerifFormations => 
        prevNotVerifFormations.map(item => 
            item.id_formation === formationId ? { ...item, imgs: newImgs } : item
        )
    );
  };

  // TODO: rewrite as transaction
  const handleSave = async (fId, text, links) => {
    var respUpload = {};

    // check if newFile is not empty
    if (newFile) {
        const formData = new FormData();
        formData.append("myFile", newFile);

        respUpload = await uploadFormationImg(formData, fId);

        if (respUpload.state != 'SUCCESS') {
            toast.error("Hiba lépett fel a képfeltöltés során.")
        }
        // console.log(respUpload);
    } else {
        respUpload.state = 'SUCCESS';
    }

    // description

    const data = {'formationId': fId, 'text': text};
    const respDescriptionUpdate = await updateFormationDescription(data);

    if (respDescriptionUpdate.state != 'SUCCESS') {
      toast.error("Hiba lépett fel a bemutatkozó szöveg mentése során.");
    }

    // links

    const data2 = {'formationId': fId, 'links': links};
    const respFormationLinks = await updateOrInsertFormationLink(data2);

    if (respFormationLinks.state != 'SUCCESS') {
        toast.error("Hiba lépett fel a linkek mentése során.")
    }

    if (respDescriptionUpdate.state == 'SUCCESS' && respFormationLinks.state == 'SUCCESS' && respUpload.state == 'SUCCESS'){
        const validateFormationProfile = await updateFormationProfileState(fId, 1);
        // console.log(validateFormationProfile);

        if (validateFormationProfile.state == 'SUCCESS') {
          toast.success("Az adatokat sikeresen mentettük!");

          // delete formation from state
          setNotVerifFormations(prevFormations => {
            return prevFormations.filter(parFormation => parFormation.id_formation !== fId);
          });
        }
        else 
          toast.success("Az adatlap publikálása nem sikerült!");
      
    }

  };


  return (
    <>
        <h1>Adatlapok kezelése</h1>
        <p>Jelenleg&nbsp; 
          <span style={{fontWeight: "bold"}}>
            {notVerifFormations.length}
          </span> adatlap vár ellenőrzésre.</p>
        {
          (notVerifFormations.length > 0) ? (
            <ul>
              {
                notVerifFormations.map((parFormation) => (
                  <li key={parFormation.id_formation}>
                    {parFormation.name_formation}
                    <button className="btn primary" key={parFormation.id_formation} onClick={() => handleClick(parFormation.id_formation)}>
                      {showMore[parFormation.id_formation] ? "Összecsuk" : "Lenyit"}
                    </button>

                    {showMore[parFormation.id_formation] ?
                      <>
                        <h2>Bemutatkozás</h2>
                        {/* <button>Szerkesztés</button> */}
                        <TextEditor getText={(e) => onTextChange(e, parFormation.id_formation)} text={parFormation.description_formation}/>
                      
                        <h2>Profilkép</h2>
                        {/* <button>Szerkesztés</button> */}
                        {
                          parFormation.imgs.length == 0 ?
                            <input
                            // style={{ display: "none" }}
                            type="file"
                            onChange={(e) => setNewFile(e.target.files[0])}
                            /> : null
                        }
                        <FormationPageImgs files={parFormation.imgs} onChangeNotify={(newImgs) => onImgChange(newImgs, parFormation.id_formation)}/>

                        <h2>Linkek</h2>
                        {/* <button>Szerkesztés</button> */}
                        <FormationPageLinks links={parFormation.links} linkChange={(e) => onLinkChange(e, parFormation.id_formation)}/>
                        {/* linkChange={(e) => onLinkChange(e, parFormation.id_formation)}/> */}

                        <button className="btn submit" onClick={() => {
                          // console.log(`You saved formation with formation_id = ${parFormation.id_formation}.`);
                          // console.log(parFormation.description_formation);
                          // console.log(parFormation.imgs);
                          // console.log(parFormation.links);
                          handleSave(parFormation.id_formation, parFormation.description_formation, parFormation.links);
                        }}>Mentés</button>
                      </> : null }
                  </li>
                ))
              }
            </ul>
          ) : null
        }
    </>
  );
}