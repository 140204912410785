import React, { useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import UserDataCopy from "./UserDataCopy";
import AddressAutocompleteCopy from "./AddressAutocompleteCopy";
import { useEffect, useCallback } from "react";
import { initialUserData, initialAddress } from "../../../util/initialFormData";
import AuthContext from "../../../context/AuthProvider";
import { updateUserBasicData } from "../../../service/user";
import toast from "react-hot-toast";

function UserDataWrapper(props) {
    const {reset, ...methods} = useForm({mode: 'onBlur', reValidateMode: 'onBlur'});
    const { auth } = useContext(AuthContext);

    const resetUserDV = useCallback(async (val) => {
        const userDataDV = {
            firstName: val?.fname_user || initialUserData.firstName,
            lastName: val?.lname_user || initialUserData.lastName,
            telNum: val?.telnum_user || initialUserData.telNum,
            email: val?.email_user || initialUserData.email,

            city: val?.city || initialAddress.city,
            country: val?.country || initialAddress.country,
            postcode: val?.postcode || initialAddress.postcode,
            street: val?.street || initialAddress.street,
            housenumber: val?.nr || initialAddress.housenumber,
            /* no-autofill attributes */
            building: val?.building || initialAddress.building,
            stairway: val?.stairway || initialAddress.stairway,
            floor: val?.floor || initialAddress.floor,
            apartment: val?.apartment || initialAddress.apartment,
            additional: val?.additional || initialAddress.additional
        };

        reset(userDataDV);
    }, [reset]);

    useEffect(() => {
        console.log('Fetched values:', props);
        resetUserDV(props.defaultValues);
    }, [resetUserDV, props.defaultValues]);


    // useEffect(() => {
    //     console.log(props.defaultValues);
    // }), [];

    async function testSubmit(data) {
        // data par + contact_person, id_frole
        // TODO: rewrite endpoint on the backend

        // console.log(data);
        // console.log(props.defaultValues.id_frole);
        // console.log(props.defaultValues.contact_person);

        const organizedData = {
            id: props.defaultValues.id_user,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            selectedRole: props.defaultValues.id_frole,
            telNum: data.telNum,
            contactPerson: props.defaultValues.contact_person,

            // address
            address: {
                city: data.city,
                country: data.country,
                postcode: data.postcode, 
                street: data.street, 
                housenumber: data.housenumber, 
                building: data.building, 
                stairway: data.stairway,
                floor: data.floor,
                apartment: data.apartment, 
                additional: data.additional, 
            }
        };

        console.log(organizedData);

        const resp = await updateUserBasicData(auth.formationId, "user", organizedData, props.entity);

        if (resp.state == 'SUCCESS')
            toast.success("A felhasználó adatait sikeresen mentettük!");
        else
            toast.error("A felhasználó adatainak mentése nem sikerült.");
    };

    return (
        <FormProvider {...methods}>
        <form className="user-data-page" onSubmit={methods.handleSubmit(testSubmit)}>
            <UserDataCopy
                userType={props.entity}
                fetchedValues={{contactperson: props.defaultValues?.contact_person, selectedRole: props.defaultValues?.id_frole}}
                handleCPChange={props.handleCPChange}
                handleUserRoleChange={props.handleUserRoleChange}
            />
            {
                (props.defaultValues?.contact_person || props.defaultValues?.id_frole == 4) ?
                <AddressAutocompleteCopy
                    entity={props.entity}
                /> : null
            }
            <button className="btn submit full-width-group">Mentés</button>
        </form>
        </FormProvider>
    )
}

export default UserDataWrapper;