import React, { useEffect, useState } from "react";
import "./FormInput.css";
import { VscError } from 'react-icons/vsc';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";


// 0 = FALSE, bármi más true
function FormInput(props) {
  const [isValid, setIsValid] = useState(false);
  const { label, required, errorMessage, onChange, id, type, validityValue, validityCheck, ...inputProps } = props;
  
  const [showPwd, setShowPwd] = useState(false);
  const toggleShowPassword = () => {
    setShowPwd(!showPwd);
  };

  // A mező kitöltése kötelező!
  const handleFocus = () => {
    // setFocused(true);

    // pattern match test
    if (props.value != '') {
      var re = new RegExp(props.regex);
      var matchRegExp = re.test(props.value);

      if (validityCheck)
        validityCheck(id, matchRegExp);
      else
        setIsValid(matchRegExp);

    } else {
      if (required) {
        if (validityCheck)
          validityCheck(id, false);
        else
          setIsValid(false);
      }
      else {
        if (validityCheck)
          validityCheck(id, true);
        else
          setIsValid(true);
      }
    }
  };

  // useEffect(() => {
  //   if (validityCheck)
  //     validityCheck(id, isValid);
  // }, [isValid]);

  return (
    <div className="formInput">
      <label htmlFor={id}>
          {label}{(required) ? <span style={{color:'red'}}>*</span> : null}
      </label>
      {
        (type != "password") ?
        <input
          className={validityValue == 0 ? "error" : null}
          {...inputProps}
          type={type}
          onChange={onChange}
          onBlur={handleFocus}
        /> :
        <>
          <span className="icons" onClick={toggleShowPassword}>
            { showPwd ? 
              <IoMdEye style={{color: "#18ab42"}}/> : 
              <IoMdEyeOff style={{color: "grey"}}/>
            }
          </span> 
          <input
            className={validityValue == 0 ? "error" : null}
            {...inputProps}
            type={type}
            onChange={onChange}
            onBlur={handleFocus}
          />         
        </>
      }
        {/* <span className="form-input-state">
          {errorMessage}
        </span> */}
        {validityValue == 0 ?
          <span className="form-input-state">
            { (props.value != '') ? errorMessage : "A mező kitöltése kötelező!"}
          </span> : null
        }
        {/* <span className="form-input-state">
          { (isValid == false) ? <VscError style={{color: 'red'}}/> : (isValid == true) ?
           <AiOutlineCheckCircle style={{color: 'green'}}/> : null}
        </span> */}
    </div>
  );
};

export default FormInput;