import React, {useState, useContext, useEffect, useRef} from 'react';
import AuthContext from '../../../context/AuthProvider';
import { Link } from 'react-router-dom';

// dropdown
import { RiArrowDropDownLine } from "react-icons/ri";

// fejecske
import { GoPerson } from "react-icons/go";

// fejecske, logout
import { CgProfile } from "react-icons/cg";

// profile edit
import { GiMusicalNotes } from "react-icons/gi";

// basic data edit
import { MdModeEdit } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";

import './MyDropdownMenu.css';
import Logout from '../users/Logout';

function MyDropdownMenu(props) {
  const { auth } = useContext(AuthContext);

  // const menuRef = useRef();

  // useEffect(() => {
  //   const handler = (e) => {
  //     if(menuRef.current && !menuRef.current.contains(e.target)){
  //       (props.callback)();
  //     }      
  //   };

  //   document.addEventListener("mousedown", handler);
    
  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };

  // }, [menuRef]);

  return (
    <div className="dropdown">
      {props.trigger}
      {/* {props.open ? ( */}
        <ul className="dropdown-content">
          {
            (props.menu).map((menuItem, index) => (
              <li key={index} className="dropdown-item">
                {menuItem}
              </li>
            ))
          }
        </ul>
      {/* ) : null} */}
    </div>
  );

  // return (
  //   <div className='menu-container' >
  //     <div className='menu-trigger' onClick={()=>{
  //       setOpen(!open);
  //       console.log("Clicked!");
  //       }}>
  //       Click
  //     </div>

  //     <div className={`dropdown-menu ${open? 'active' : 'inactive'}`} >
  //       {/* <h3>The Kiet<br/><span>Website Designer</span></h3> */}
  //       <ul>
  //         <DropdownItem text = {"Adatok módosítása"}/>
  //         <DropdownItem text = {"Adatlap kitöltése"}/>
  //         <DropdownItem text = {"Kijelentkezés"}/>
  //       </ul>
  //     </div>
  //   </div>
  // );
}

function DropdownItem(props){
    return(
      <li className ='dropdownItem'>
        {/* <img src={props.img}></img> */}
        <a> {props.text} </a>
      </li>
    );
  }

export default MyDropdownMenu;