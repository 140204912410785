import React, {useContext} from 'react';
import {Route, Routes, Navigate, Outlet} from 'react-router-dom';
import './Main.css';
import {UnderConstruction} from '../App';
import AboutUs from './mainMenu/AboutUs';
import LoginForm from './forms/Login';
import RegistrationForm from './forms/RegistrationForm';
import RegistrationFormAdmin from './forms/RegistrationFormAdmin';

import NewPassword from './forms/NewPassword';

import Formations from './mainMenu/formations/Formations';
import FormationPageUpdate from './mainMenu/users/FormationPageUpdate';
import FormationDataUpdateForm from './forms/BasicDataUpdateForm';
import FormationPageManagement from './mainMenu/users/FormationPageManagement';

import Events from './mainMenu/events/Events';
import EventPage from './mainMenu/events/EventPage';
import NewEvent from './mainMenu/events/NewEvent';

import RedirectPage from './RedirectPage';
import LandingPage from './LandingPage';

import { BsCheckLg } from 'react-icons/bs';
import AuthContext from '../context/AuthProvider';
import FormationPage from './mainMenu/formations/FormationPage';
import Contact from './mainMenu/Contact';

import { Toaster } from 'react-hot-toast';
import ScrollToAnchor from './mainMenu/elementary/ScrollToAnchor';

const ProtectedRoute = ({
    isAllowed,
    redirectPath = '/',
    children,
  }) => {
    if (!isAllowed) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children ? children : <Outlet />;
  };

function Main() {
    const { auth } = useContext(AuthContext);
    // console.log(auth);

    return (
        <main>
            <ScrollToAnchor/>
            <Routes>
                <Route index element={<LandingPage/>}/>
                <Route path="/rolunk" element={<AboutUs/>}/>
                {/* Adatlapok kezelése */}
                {/* <Route path="/egyuttesek" element={<Formations/>}/>
                <Route path="/egyuttesek/:id/*">
                    <Route index element={<FormationPage/>}/>
                    <Route path="adatlap" element={<FormationPageUpdate/>} />
                </Route> */}

                <Route path="/egyuttesek/*">
                    <Route index element={<Formations/>}/>
                    <Route path=":id" element={<FormationPage/>}/>
                    {/* TODO: set permissions */}
                    <Route path="adatlapok/*"> 
                    {/* element={<ProtectedRoute isAllowed={auth?.roleName === "admin"} redirectPath='/unauthorized'/>}> */}
                        <Route index element={<FormationPageManagement/>}/> 
                        <Route path=":id" element={<FormationPageUpdate/>}/>
                    </Route>
                </Route>

                <Route path="/esemenyek/*">
                    <Route index element={<Events/>}/>
                    <Route path=":id" element={<EventPage/>}/>
                    <Route path="uj/*" element={<ProtectedRoute isAllowed={auth?.roleName === "admin"} redirectPath='/unauthorized'/>}>
                        <Route index element={<NewEvent/>}/> 
                    </Route>
                </Route>

                <Route path="/galeria" element={<UnderConstruction/>}/>
                <Route path="/archivum" element={<UnderConstruction/>}/>
                <Route path="/kapcsolat" element={<Contact/>}/>
                <Route path="/bejelentkezes" element={<LoginForm/>}/>
                <Route path="/regisztracio/*">
                    <Route index element={<RegistrationForm/>}/>
                    <Route path="admin" element={<RegistrationFormAdmin/>} />
                    <Route path="landing" element={<RedirectPage title={<BsCheckLg style={{color: 'green'}}/>} text={["Adatait rögzítettük.", <br/>, "A regisztráció befejezéséhez kérjük lépjen be a postafiókjába."]}/>} />
                </Route>

                <Route path="/felhasznalok/:userID/*" element={<ProtectedRoute isAllowed={auth} redirectPath='/unauthorized'/>}>
                    <Route path="adatok" element={<FormationDataUpdateForm/>} />
                </Route>

                <Route path="/jelszo/:userID/:key/*" element={<NewPassword/>}>
                    {/* // TODO: this is not working here */}
                    <Route path="landing" element={<RedirectPage title={<BsCheckLg style={{color: 'green'}}/>} text={["A regisztráció sikeres!", <br/>, "Kérjük, jelentkezzen be."]}/>} />
                </Route>

                <Route path="/landing" element={<RedirectPage title={<BsCheckLg style={{color: 'green'}}/>} text={["A regisztráció sikeres!", <br/>, "Kérjük, jelentkezzen be."]}/>} />
                <Route path='/unauthorized' element={<RedirectPage title="401" text="Az oldal megtekintéséhez kérjük, előbb jelentkezzen be!"/>}/>
                <Route path="*" element={<RedirectPage title="404" text="A kért oldal nem található."/>}/>
            </Routes>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
                toastOptions={{
                    className: '',
                    // style: {
                    //     border: '1px solid #713200',
                    //     padding: '16px',
                    //     color: '#713200',
                    // },
                }}
            />
        </main>
    );
}

export default Main;