import React from "react";
// import { useLocation } from "react-router-dom";
import './RedirectPage.css';
// TODO: somehow resolve this location stuff
function RedirectPage(props) {
    // console.log('Hello props', props);
    // const location = useLocation();
    // console.log(location.state?.title, location.state?.text);

    return (
        <>
            <h1 id="redirect-header">{props?.title}</h1>
            <p id="redirect-msg">{props?.text}</p>
        </>
    );
}

export default RedirectPage;