import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
// import './Forms.css';
import {initialUserData, initialUserDataValidity, initialUserDataCPValidity, initialUserDataLeaderValidity} from '../../util/initialFormData';
import {registerUser} from '../../service/user';
import {sendEmail} from '../../service/email';
import FormInput from './elementary/FormInput';
import MyDropdown from "./elementary/MyDropdown";
import { BsCheckLg } from 'react-icons/bs';
import toast from "react-hot-toast";

function RegistrationForm() {
  const nav = useNavigate();
  
  const FormTitles = ["Formáció adatai", "Regisztráló személy adatai", "Kapcsolattartó személy adatai", "Karnagy adatai"];

  const [page, setPage] = useState(0);

  const [formationName, setFormationName] = useState('');

  const [userData, setUserData] = React.useState(initialUserData);
  const [contactPersonF, setcontactPersonF] = useState(false);

  const [userDataCP, setUserDataCP] = React.useState(initialUserData);

  const [userDataLeader, setUserDataLeader] = React.useState({...initialUserData, selectedRole: "4"});

  // const [globalErrMsg, setGlobalErrMsg] = useState('');

  useEffect(() => {
    // console.log(contactPersonF);
    // console.log('Current page is: ' + page);

    setUserData({...userData, contactPerson: contactPersonF});
    setUserDataCP({...userDataCP, contactPerson: !contactPersonF});

    // console.log('Updating state with new values:', userData);
    // console.log('Updating state with new values:', userDataCP);
    // console.log('Updating state with new values:', userDataLeader);
  }, [contactPersonF]);

  const inputs_page1 = [
    {
      id: "lastName",
      name: "lastName",
      type: "text",
      // placeholder: "Vezetéknév",
      errorMessage:
        "Hibás vezetéknév formátum!",
      label: "Vezetéknév",
      regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
      // match: null,
      required: true,
    },
    {
      id: "firstName",
      name: "firstName",
      type: "text",
      // placeholder: "Keresztnév",
      errorMessage:
        "Hibás keresztnév formátum!",
      label: "Keresztnév",
      regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
      // match: null,
      required: true,
    },
    {
      id: "email",
      name: "email",
      type: "text",
      // placeholder: "E-mail cím",
      errorMessage:
        "Nem megfelelő e-mail cím formátum! Példa: pelda@valami.ro",
      label: "E-mail cím",
      regex: "^[\\\w\\.\\-]{2,20}@[\\\w\\.\\-]{2,20}\\.[\\\w\\-]{2,4}$",
      // match: null,
      required: true,
    },
    {
      id: "telNum",
      name: "telNum",
      type: "text",
      // placeholder: "Telefonszám",
      errorMessage:
        "Nem megfelelő telefonszám formátum!",
      label: "Telefonszám",
      regex: "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
      // match: null,
      required: true,
    },
  ];

  const inputs_page2 = [
    {
      id: "lastNameCP",
      name: "lastName",
      type: "text",
      // placeholder: "Vezetéknév",
      errorMessage:
        "Hibás vezetéknév formátum!",
      label: "Vezetéknév",
      regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
      // match: null,
      required: true,
    },
    {
      id: "firstNameCP",
      name: "firstName",
      type: "text",
      // placeholder: "Keresztnév",
      errorMessage:
        "Hibás keresztnév formátum!",
      label: "Keresztnév",
      regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
      // match: null,
      required: true,
    },
    {
      id: "emailCP",
      name: "email",
      type: "text",
      // placeholder: "E-mail cím",
      errorMessage:
        "Nem megfelelő e-mail cím formátum! Példa: pelda@valami.ro",
      label: "E-mail cím",
      regex: "^[\\\w\\.\\-]{2,20}@[\\\w\\.\\-]{2,20}\\.[\\\w\\-]{2,4}$",
      // match: null,
      required: true,
    },
    {
      id: "telNumCP",
      name: "telNum",
      type: "text",
      // placeholder: "Telefonszám",
      errorMessage:
        "Nem megfelelő telefonszám formátum!",
      label: "Telefonszám",
      regex: "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
      // match: null,
      required: true,
    },
  ];

  const inputs_page3 = [
    {
      id: "lastNameLeader",
      name: "lastName",
      type: "text",
      // placeholder: "Vezetéknév",
      errorMessage:
        "Hibás vezetéknév formátum!",
      label: "Vezetéknév",
      regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
      // match: null,
      required: true,
    },
    {
      id: "firstNameLeader",
      name: "firstName",
      type: "text",
      // placeholder: "Keresztnév",
      errorMessage:
        "Hibás keresztnév formátum!",
      label: "Keresztnév",
      regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
      // match: null,
      required: true,
    },
    {
      id: "telNumLeader",
      name: "telNum",
      type: "text",
      // placeholder: "Telefonszám",
      errorMessage:
        "Nem megfelelő telefonszám formátum!",
      label: "Telefonszám",
      regex: "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
      // match: null,
      required: true,
    },
  ];

  //////////////////////////////
  // GLOBAL INPUT VALIDATION //
  /////////////////////////////

  function allowNextButton(obj) {
    let result = true;

    // Iterate through the attributes of the object
    for (let key in obj) {
        // Check if the value is truthy
        if (obj.hasOwnProperty(key) && obj[key] <= 0) {
            // If any value is falsy, set result to false and break the loop
            result = false;
            break;
        }
    }

    // Return the result
    return result;
}

  const [formValidity, setFormValidity] = useState([
    {formationName: -1}, initialUserDataValidity, initialUserDataCPValidity, initialUserDataLeaderValidity]);

    function userDataValidityChange(page, key, valValue) {
      setFormValidity(prevValue => {
          const updatedFormValidity = [...prevValue];
          updatedFormValidity[page] = {
              ...prevValue[page],
              [key]: valValue
          };
          return updatedFormValidity;
      });
    }

  useEffect(() => {
    console.log(formValidity[page]);
  }, [formValidity]);

  const submitRegForm = async (e) => {
    e.preventDefault();
    // TODO: check if all inputs are valid on the last page, if yes, let's submit the form
    // TODO: set the data of the Leader

    // var regData = {};

    var regData = { formationName, userData, userDataCP, userDataLeader };
    // console.log(`I sent to the server: ${JSON.stringify(regData)}`);

    const respUserReg = await registerUser(regData);

  // ha sikerült az előbbi beszúrás, mehet az e-mail küldés

  //  check what do these functions return
    if (respUserReg.state == 'SUCCESS') {
      const respEmailSending = await sendEmail({email: userData.email});
      // console.log(respEmailSending); 
      if (respEmailSending.state != 'SUCCESS')
        toast.error("Az e-mail küldése nem sikerült,\n kérjük vegye fel a kapcsolatot a rendszergazdával!");
      else
        nav('landing');
    } else {
      toast.error("A regisztráció sikertelen!");
      // setGlobalErrMsg(respUserReg.mess);
      // alert(respUserReg.mess);
    }
  };

  const userDataChange = (e) => {
    setUserData({...userData, [e.target.name]: e.target.value});
    // console.log(userData.selectedRole);
    // console.log(/^[A-Za-z.-]{2,20}$/.test(e.target.value));
    //console.log(userData);
  };

  const userDataCPChange = (e) => {
    setUserDataCP({...userDataCP, [e.target.name]: e.target.value});
    //console.log(userDataCP.selectedRole);
    // console.log(userDataCP);
  };

  const userDataLeaderChange = (e) => {
    setUserDataLeader({...userDataLeader, [e.target.name]: e.target.value});
    //console.log(userDataCP.selectedRole);
    // console.log(userDataCP);
  };

  const handleRight = (e) => {
    e.preventDefault();
    // if (page === FormTitles.length - 1 || (contactPersonF && page == 1)) {
    //   submitRegForm();
    // } else {
      // TODO: check if all inputs are valid on the page, if yes, let's increase page number
      // inputs_page1.map((input) =>
      //   console.log(`${input.label}: ${input.match}`)
      // );
      if (allowNextButton(formValidity[page])){
        if (page < 3) {
          if (contactPersonF && page == 1)
            setPage((currPage) => currPage + 2);
          else
            setPage((currPage) => currPage + 1);
        }
      } else
        toast.error("A csillaggal jelölt mezők kitöltése kötelező!");
  }

  const handleLeft = (e) => {
    e.preventDefault();
    if (page > 0) {
      if (contactPersonF && page == 3)
        setPage((currPage) => currPage - 2);
      else
        setPage((currPage) => currPage - 1);
    }
  }

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <>
            <FormInput
              id="formationName" 
              name="formationName" 
              value={formationName} 
              type="text" 
              label="Formáció neve"
              regex="^[0-9A-Z\u00C0-\u00DC][0-9A-Z\u00C0-\u00DCa-z\u00E0-\u00FC0-9#@\-\. ]{1,31}$"
              required={true}
              errorMessage="Hibásan megadott formációnév!"
              onChange={(e) => setFormationName(e.target.value)}
              validityValue={formValidity[0].formationName}
              validityCheck={(parKey, parVal) => userDataValidityChange(page, parKey, parVal)}
            />

        </>
      );
    } else if (page === 1) {
        return (
            <>
                {inputs_page1.map((input) => (
                    <>
                      <FormInput
                        key={input.id}
                        {...input}
                        value={userData[input.name]}
                        onChange={userDataChange}
                        validityValue={formValidity[1][input.id]}
                        validityCheck={(parKey, parVal) => userDataValidityChange(page, parKey, parVal)}
                      />
                    </>
                ))}

                {/* // TODO: add to validation state object */}
                <div className="formInput">
                  <label htmlFor="selectedRole">Titulus<span style={{color:'red'}}>*</span></label>
                  <MyDropdown value={userData.selectedRole} name="selectedRole" id="selectedRole" onChange={userDataChange}/>
                </div>
                <div className="formInput">
                  <label htmlFor="contactPerson">Ön a kapcsolattartó személy?</label>
                  <input type="checkbox" id="contactPerson" name="contactPerson"
                  checked={contactPersonF} onChange={() => setcontactPersonF(!contactPersonF)}/>
                </div>
            </>   
        );
    } else if (page === 2) {
        return (
            <>
              {inputs_page2.map((input) => (
                  <>
                    <FormInput
                      key={input.id}
                      {...input}
                      value={userDataCP[input.name]}
                      onChange={userDataCPChange}
                      validityValue={formValidity[2][input.id]}
                      validityCheck={(parKey, parVal) => userDataValidityChange(page, parKey, parVal)}
                    />
                  </>
              ))}

            {/* // TODO: add to validation state object */}
              <div className="formInput">
                <label htmlFor="selectedRoleCP">Titulus<span style={{color:'red'}}>*</span></label>
                <MyDropdown value={userDataCP.selectedRole} 
                name="selectedRole" 
                id="selectedRoleCP" 
                onChange={userDataCPChange}/>
              </div>

            </>            
        );
    } else {
      return (
        <>
          {inputs_page3.map((input) => (
              <>
                <FormInput
                  key={input.id}
                  {...input}
                  value={userDataLeader[input.name]}
                  onChange={userDataLeaderChange}
                  validityValue={formValidity[3][input.id]}
                  validityCheck={(parKey, parVal) => userDataValidityChange(page, parKey, parVal)}
                />
              </>
          ))}
        </>
      );     
    }
  };

  return (
    <>
    <h1>Regisztráció</h1>
    {/* <div className="form"> */}
    <form onSubmit={submitRegForm}>
      {/* <div className="progressbar">
        <div
          style={{ width: page === 0 ? "33.3%" : page == 1 ? "66.6%" : "100%" }}
        ></div>
      </div> */}

      <div className="form-container">
        <div className="header">
          <h2>{FormTitles[page]}</h2>
        </div>

        <div className="body">{
          PageDisplay()
        }</div>

        <div className="footer">
          <div>
            {/* <span className="error-msg-bottom"
            style={{display: 'block', textAlign: 'left', color: 'red'}}>
              {(globalErrMsg != '') ? globalErrMsg : null }
            </span> */}
            <button 
              type="button"
              disabled={page == 0} 
              onClick={handleLeft}>
                &lt;
            </button>

            {
              page === FormTitles.length - 1 || 
              ((userData.selectedRole == 4 || userDataCP.selectedRole == 4) && page == 2) ||
              (userData.selectedRole == 4 && contactPersonF && page == 1) ?
              <button type="submit" className="submit">Küldés</button> : 
              <button type="button" 
                // disabled={!allowNextButton(formValidity[page])}
                onClick={handleRight}>
                  &gt;
              </button>
            }
            
          </div>
        </div>

      </div>
    {/* </div> */}
    </form>
    </>
  );
}

export default RegistrationForm;