import React from 'react';
import {Link} from 'react-router-dom';
import './SideBar.css';
import { IoLogoFacebook } from "react-icons/io5";

function SideBar() {
    return (
        <div id='side-bar'>
            <Link to="/">
                <img id='side-bar-logo' className='side-bar-child' src="img/RMD_logo_white.svg"/>
            </Link>
            <div id='logo-title' className='side-bar-child'>
                {/* <div id='hide-item'>RMD</div> */}
                {/* <img src='img/RMD_logo_white.svg'/> */}
                <img src='img/cim_logo.png'/>
                {/* <p>Hello Hello Hello Hello Hello Hello Hello Hello Hello</p> */}
                {/* <div id='logo-texts'>
                    <p id='main-title'>Romániai Magyar Dalosszövetség</p>
                    <div id='secondary-title-wrapper'>
                        <p className='secondary-title'>Uniunea Formaţiilor Muzicale Maghiare din România</p>
                        <span>•</span>
                        <p className='secondary-title'>•</p>
                        <p className='secondary-title'>Hungarian Musical Union <br/>from Romania</p>
                    </div>
                </div> */}
            </div>
            <div id='footer' className='side-bar-child'>
                <p>
                <a href="https://www.facebook.com/dalosszovetseg.ro" target="_blank">
                    <IoLogoFacebook style={{fontSize: '30px', color: 'white'}}/>
                </a>
                </p>
                <p>RMD &copy; 2024</p>
            </div>
        </div>
    );
}

export default SideBar;