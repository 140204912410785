import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import './NavBar.css';
import AuthContext from '../context/AuthProvider';
import Logout from './mainMenu/users/Logout';
import MyDropdownMenu from './mainMenu/elementary/MyDropdownMenu';

// fejecske, logout
import { CgProfile } from "react-icons/cg";
// dropdown
import { RiArrowDropDownLine } from "react-icons/ri";
// basic data edit
import { MdModeEdit } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
// profile edit
import { GiMusicalNotes } from "react-icons/gi";

function NavBar() {
    const { auth } = useContext(AuthContext);
    // const [open, setOpen] = useState(false);

    // const handleOpen = () => {
    //     setOpen(!open);
    //     console.log(`It's ${open}!`);
    //   };

    // const handleClickOutside = () => {
    //     setOpen(false);
    // };

    return (
        <nav>
            <Link to="/rolunk">Rólunk</Link>
            <Link to="/egyuttesek">Együttesek</Link>
            <Link to="/esemenyek">Események</Link>
            <Link to="/galeria">Galéria</Link>
            <Link to="/archivum">Archívum</Link>
            <Link to="/kapcsolat">Kapcsolat</Link>
            {
                // check if auth context-object is empty
                (!auth) ?
                    <Link to="/bejelentkezes">Bejelentkezés</Link> :
                    <MyDropdownMenu
                        // callback={handleClickOutside}
                        // open={open}
                        trigger={
                            <button className="dropbtn">
                                <RiArrowDropDownLine/>{auth.userLastName} {auth.userFirstName} <CgProfile/>
                            </button>
                        }
                        menu={
                            (auth.roleName === "admin") ?
                        [
                            // <Link to={"/felhasznalok/" + auth.userID + "/adatok"}>
                            //     <FaUserEdit/>&nbsp;Adatok módosítása
                            // </Link>,
                            <Link to={"/egyuttesek/adatlapok"}>
                                <GiMusicalNotes/>&nbsp;Adatlapok kezelése
                            </Link>,
                            <Logout/>
                        ]
                        :
                        [
                            <Link to={"/felhasznalok/" + auth.userID + "/adatok"}>
                                <FaUserEdit/>&nbsp;Adatok módosítása
                            </Link>,
                            <Link to={"/egyuttesek/adatlapok/" + auth.formationId}>
                                <GiMusicalNotes/>&nbsp;Adatlap szerkesztése
                            </Link>,
                            <Logout/>
                        ]}
                    />
                    // <Logout/>
            }
        </nav>
    );
}

export default NavBar;