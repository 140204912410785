import { apiBase } from '../util/constants';

export async function getAllFormationRoles() {
    try {
        const response = await fetch(`${apiBase}/formations/roles`);
        const resp = await response.json();
        // console.log(resp);
        return resp;
    } catch (e) {
        return e;
    }  
  }

  export async function getAllFormationTypes() {
    try {
        const response = await fetch(`${apiBase}/formations/types`);
        const resp = await response.json();
        // console.log(resp);
        return resp;
    } catch (e) {
        return e;
    }  
  }


  // FORMATION PUBLIC PROFILE

  export async function updateFormationDescription(data) {
    // console.log(JSON.stringify(data.text));
    const settings = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({text: data.text}),
      credentials: 'include',
    };
    try {
        const fetchResponse = await fetch(`${apiBase}/loggedinFormations/${data.formationId}/description`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }    
  }

  export async function updateOrInsertFormationLink(data) {
    // console.log(JSON.stringify(data.text));
    const settings = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    };
    try {
        const fetchResponse = await fetch(`${apiBase}/loggedinFormations/${data.formationId}/links`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }    
  }

  export async function sendFormationProfileToValidate(formationId, state) {
    const settings = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    try {
        const fetchResponse = await fetch(`${apiBase}/loggedinFormations/${formationId}?state=${state}`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }   
  }

  export async function getFormationProfileData(formationId) {
    try {
        const response = await fetch(`${apiBase}/formations/${formationId}/profile`);
        const resp = await response.json();
        // console.log(resp);
        return resp;
    } catch (e) {
        return e;
    }  
  }

  export async function getAllFormationData() {
    try {
        const response = await fetch(`${apiBase}/formations`);
        const resp = await response.json();
        // console.log(resp);
        return resp;
    } catch (e) {
        return e;
    }  
  }

  export async function getFormationBasicData(formationId) {
    const settings = {
      credentials: 'include',
    };
    try {
        const response = await fetch(`${apiBase}/loggedinFormations/${formationId}/data`, settings);
        const resp = await response.json();
        // console.log(resp);
        return resp;
    } catch (e) {
        return e;
    }  
  }

  // BASIC DATA UPDATE

  export async function updateFormationBasicData(formationId, data) {
    const settings = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({'data': data}),
      credentials: 'include',
    };
    try {
        const fetchResponse = await fetch(`${apiBase}/loggedinFormations/${formationId}/data/formation`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }   
  }

  // FORMATION PROFILE VERIFICATION BY ADMIN 

  export async function getNVFormations() {
    const settings = {
      credentials: 'include',
    };
    try {
        const response = await fetch(`${apiBase}/adminFormations/notVerified`, settings);
        const resp = await response.json();
        // console.log(resp);
        return resp;
    } catch (e) {
        return e;
    }  
  }

  export async function updateFormationProfileState(formationId, state) {
    const settings = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };
    try {
        const fetchResponse = await fetch(`${apiBase}/adminFormations/${formationId}?state=${state}`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }   
  }
