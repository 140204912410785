import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IoLocationSharp } from 'react-icons/io5';
import { IoCalendarSharp } from 'react-icons/io5';
// import { RiArrowGoBackLine } from 'react-icons/ri';
import { MdOutlineArrowBack } from 'react-icons/md';
import { GrPrevious } from 'react-icons/gr';
import { HiClock } from 'react-icons/hi';

import { AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import AuthContext from '../../../context/AuthProvider';

import FsLightbox from 'fslightbox-react';

// TODO: connect with backend and fetch event data based on event ID
import { ourEvents } from '../events/EventData';
// import "./MainMenu.css";

export default function EventPage() {
//export default class AboutUs extends React.Component{
 // render() {
    const { auth } = useContext(AuthContext);
    const { id } = useParams();
    const event = ourEvents.find(x => x.id == id);
    // console.log(event);
    const month = ["január","február","március","április","május","június","július","augusztus","szeptember","október","november","december"];

    let videoLinks2 = event.links ? event.links[0].lLink.map((videoId, index) => (
        `https://www.youtube.com/embed/${videoId}`)
    ) 
    : [];

	const [toggler, setToggler] = useState(false);
    const [productIndex, setProductIndex] = useState(0);

    useEffect(() => {
        FB.XFBML.parse();
    }, []);

    return (
    <div>
        <p style={{display: 'flex', justifyContent: 'space-between'}}>
            <Link to="/esemenyek"><GrPrevious/>&nbsp;Többi esemény</Link>
            {
                (auth?.roleName === "admin") ?
                <div>
                    <button className="submit"><AiOutlineEdit/> Szerkesztés</button>
                    &nbsp;&nbsp;
                    <button className="submit"><AiOutlineDelete/> Törlés</button>
                </div> : null
            }
        </p>
        <h1>{event.title}</h1>

        <div className="text">
            <IoCalendarSharp/>&nbsp;
            {event.startDate.getFullYear()}.&nbsp;
            {month[event.startDate.getMonth()]}&nbsp;
            {event.startDate.getDate()}.
            <br/>

            {event.startDate.getHours() >= 7 ?
            <>
            <HiClock/>&nbsp;
            {("0" + event.startDate.getHours()).slice(-2)}:{("0" + event.startDate.getMinutes()).slice(-2)}
            <br/>
            </> : null }

            <IoLocationSharp/>&nbsp;{event.place}
            <br/><br/>
            {/* {event.description} */}
            <div dangerouslySetInnerHTML={{ __html: event.description }} />
        </div>    

        {(event.files || event.links || event.facebookPost) ?
            <>
                <h2>Kapcsolódó médiatartalmak</h2>

                    <ul>
                    {
                        (event.files && event.files.map((f, index) => (
                            <li>
                                <a href={f.fLink} target="_blank" key={index} rel="noreferrer">{f.fDescription}</a>
                            </li>
                        )))
                    }
                    </ul>


            {event.facebookPost ? 
            <div className="fb-post" data-href={event.facebookPost}></div>: null}
            {
                videoLinks2.length > 0 ?
                <>
                    <h3>Videók</h3>
                    {/* <button onClick={() => {
                        setToggler(!toggler);
                        // setProductIndex(1);
                    }}>
                        Videók
                    </button> */}

                    <div className='uploaded-imgs' style={{flexWrap: 'wrap', justifyContent: 'center'}}>
                    {
                        event.links[0].lLink.map((videoId, index) => (
                            <img key={index} 
                            src={`http://img.youtube.com/vi/${videoId}/0.jpg`}
                            onClick={() => {
                                setProductIndex(index);
                                setToggler(!toggler);
                            }}
                            style={{cursor: "pointer", height: '150px'}}/>
                        ))
                    }   
                    </div>

                    <FsLightbox
                        toggler={toggler}
                        // sources={[
                        // 	'https://i.imgur.com/fsyrScY.jpg',
                        // 	'https://www.youtube.com/watch?v=3nQNiWdeH2Q',
                        // 	'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
                        // ]}
                        sources={videoLinks2}
                        sourceIndex={productIndex}
                    />
                </> : null
            }

            </> : null
        }
    </div>
    );
}