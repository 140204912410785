import { apiBase } from '../util/constants';

export async function getAllEvents() {
  try {
    const response = await fetch(`${apiBase}/events`);
    const resp = await response.json();
    // console.log(resp);
    return resp;
  } catch (e) {
      return e;
  }    
}

export async function updateOrInsertEventData(data) {
    // console.log(JSON.stringify(data));

    const settings = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    };

    try {
        const fetchResponse = await fetch(`${apiBase}/adminEvents`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }    
  }

  export async function uploadEventFiles(data, eventId) {
    // console.log(data);
    const settings = {
      method: 'POST',
      body: data,
      credentials: 'include'
    };
    try {
        // TODO: set description of the files
        const fetchResponse = await fetch(`${apiBase}/adminEvents/files/${eventId}`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }    
  }