import { initialUserData } from "../../util/initialFormData";
import React from "react";
import { useNavigate } from 'react-router-dom';
import FormInput from "./elementary/FormInput";
import { registerAdmin } from "../../service/user";
import { sendEmail } from "../../service/email";

function RegistrationFormAdmin() {
    const nav = useNavigate();

//   const handleClick = () => {
//     nav('/regisztracio/landing');
//   };
    const [userData, setUserData] = React.useState(initialUserData);
    const userDataChange = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value});
        // console.log(userData.selectedRole);
        // console.log(/^[A-Za-z.-]{2,20}$/.test(e.target.value));
        //console.log(userData);
      };

    const inputs = [
        {
          id: "lastNameAdmin",
          name: "lastName",
          type: "text",
          // placeholder: "Vezetéknév",
          errorMessage:
            "Hibás vezetéknév formátum!",
          label: "Vezetéknév",
          regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
          // match: null,
          required: true,
        },
        {
          id: "firstNameAdmin",
          name: "firstName",
          type: "text",
          // placeholder: "Keresztnév",
          errorMessage:
            "Hibás keresztnév formátum!",
          label: "Keresztnév",
          regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
          // match: null,
          required: true,
        },
        {
          id: "emailAdmin",
          name: "email",
          type: "text",
          // placeholder: "E-mail cím",
          errorMessage:
            "Nem megfelelő e-mail cím formátum! Példa: pelda@valami.ro",
          label: "E-mail cím",
          regex: "^[\\\w\\.\\-]{2,20}@[\\\w\\.\\-]{2,20}\\.[\\\w\\-]{2,4}$",
          // match: null,
          required: true,
        },
        {
          id: "telNumAdmin",
          name: "telNum",
          type: "text",
          // placeholder: "Telefonszám",
          errorMessage:
            "Nem megfelelő telefonszám formátum!",
          label: "Telefonszám",
          regex: "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
          // match: null,
          required: true,
        },
      ];

      const submitRegForm = async (e) => {
        e.preventDefault();
        // TODO: check if all inputs are valid, if yes, let's submit the form
    
        console.log(`I sent to the server: ${JSON.stringify(userData)}`);
    
        const respAdminReg = await registerAdmin(userData);
    
      // ha sikerült az előbbi beszúrás, mehet az e-mail küldés
    
      //  check what do these functions return
        if (respAdminReg.state == 'SUCCESS') {
          const respEmailSending = await sendEmail({email: userData.email});
          // console.log(respEmailSending); 
          if (respEmailSending.state != 'SUCCESS')
            console.log(respEmailSending.mess);
          else {
            //TODO: I hacked it, but it's should be /regisztracio/admin/landing
            nav('/regisztracio/landing');
          }
        } else
          // setGlobalErrMsg(respUserReg.mess);
          alert(respAdminReg.mess);
      };

    return (
        <>
            <h1>Admin regisztráció</h1>
            <form onSubmit={submitRegForm}>
                <div className="form-container">
                    <div className="header">
                        <h2></h2>
                    </div>
                    <div className="body">
                        {inputs.map((input) => (
                                <>
                                <FormInput
                                    key={input.id}
                                    {...input}
                                    value={userData[input.name]}
                                    onChange={userDataChange}
                                    // match={formEValidity[page][formationName]}
                                    // initialUserDataValidity
                                />
                                </>
                        ))}
                    </div>
                    <div className="footer">
                        <div>
                            <button type="submit" className="submit">Küldés</button>
                            {/* <button onClick={handleClick}>Go to other page</button> */}
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default RegistrationFormAdmin;