import ReactDOM from 'react-dom';
import './App.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import React from 'react';

const root = document.getElementById('root');
ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <App/>
        </AuthProvider>
    </React.StrictMode>, root);