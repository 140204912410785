// city, country, postcode, street, housenumber, place_id, formatted
export const initialAddress = Object.freeze({
  city: "",
  country: "",
  postcode: "", 
  street: "",
  housenumber: "",
  /* no-autofill attributes */
  building: "",
  stairway: "",
  floor: "",
  apartment: "",
  additional: ""
});

export const initialEventData = Object.freeze({
  title: "",
  startDate: '',
  endDate: '',
  place: initialAddress,
  // description: 'Ez egy leírás',
  // organizers: [],
  // files: [],
  // coverPhoto: {},
  // links: []
});

export const initialUserData = Object.freeze({
  id: "",
  firstName: "",
  lastName: "",
  selectedRole: "",
  email: "",
  telNum: "",
  contactPerson: false,
  address: initialAddress
});

export const initialUserDataControlledParts = Object.freeze({
  id_frole: "",
  contact_person: false,
});

export const initialLinks = Object.freeze({
  ownLink: "",
  youtubeLink: "",
  facebookLink: "",
  instagramLink: ""
});

export const initialOfficialNames = Object.freeze({
  huName: "", 
  roName: ""
});

export const initialFormationData = Object.freeze({
  formationName: "",
  foundationYear: "",
  subCategory: "",
  isProfessionalFormation: false,
  // officialFormationNames: initialOfficialNames,
  // address: initialAddress
});

//////////////////////////////
// GLOBAL INPUT VALIDATION //
/////////////////////////////

// minden non-visited = -1 kezdetben, FALSE = 0, TRUE = 1

// regisztracio

export const initialUserDataValidity = Object.freeze({
  firstName: -1,
  lastName: -1,
  selectedRole: 1,
  email: -1,
  telNum: -1
});

export const initialUserDataCPValidity = Object.freeze({
  firstNameCP: -1,
  lastNameCP: -1,
  selectedRoleCP: 1,
  emailCP: -1,
  telNumCP: -1
});

export const initialUserDataLeaderValidity = Object.freeze({
  firstNameLeader: -1,
  lastNameLeader: -1,
  telNumLeader: -1
});

// basic data update oldal

// entity = Formation, "", CP, Leader
export function initialAddressValidity(entity) {
  return Object.freeze({
    [`country${entity}`]: -1,
    [`postcode${entity}`]: -1,
    [`city${entity}`]: -1,
    [`street${entity}`]: -1,
    [`housenumber${entity}`]: -1,
    [`building${entity}`]: -1,
    [`stairway${entity}`]: -1,
    [`floor${entity}`]: -1,
    [`apartment${entity}`]: -1,
    [`additional${entity}`]: -1
  });
};

// entity = "", CP, Leader
export function initialUserDValidity(entity) {
  return Object.freeze({
    [`lastName${entity}`]: -1,
    [`firstName${entity}`]: -1,
    [`email${entity}`]: -1,
    [`telNum${entity}`]: -1,
    [`selectedRole${entity}`]: 1
    // check `contactPerson${entity}` -> initialAddressValidity(entity)
  });

};

export const initialFormationDataValidity = Object.freeze({
  fformationName: -1,
  ffoundationYear: -1,
  subCategory: 1
  // check isProfessionalFormation
});

// if isProfessionalFormation:
export const initialProFormationDataValidity = Object.freeze({
  formationNameHu: -1,
  formationNameRo: -1
  // check initialAddressValidity("Formation")
});



