import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Slideshow.css";

function Slideshow(props) {
  const { toShow } = props;
  const [rounds, setRounds] = useState(1);

  // useEffect(() => {
  //   const onLoad = () => {
  //     move();
  //   };
  //   window.addEventListener('load', onLoad);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('load', onLoad);
  //   };
  // }, [rounds]); // Empty dependency array to run only once

  // function move() {
  //   setRounds(prevRounds => prevRounds + 1);
  // }

  return (

    <div className="logos">
       {
        [...Array(rounds)].map((_, ind) => (

        <div key={ind} class="logos-slide">
          {
            toShow.map((obj, index) => (
              <Link key={index} to={"/esemenyek/" + obj.id}>
                <img src={obj.coverPhoto.img}/>
              </Link>
            ))
          }

        </div>
        ))
      }
    </div>

  );
}

export default Slideshow;