import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import './Forms.css';
import {loginUser} from '../../service/user';
import {Link} from 'react-router-dom';
import FormInput from "./elementary/FormInput";
import AuthContext from "../../context/AuthProvider";
import toast from 'react-hot-toast';
import { useForm, FormProvider } from "react-hook-form";
import FormInputUncontrolled from "./elementary/FormInputUncontrolled";
import InfoBubble from "../mainMenu/elementary/InfoBubble";

function LoginForm() {
  const methods = useForm({mode: 'onBlur', reValidateMode: 'onBlur'});
  const nav = useNavigate();
  const { setAuth } = useContext(AuthContext);

  async function submitLoginForm(data) {
    // e.preventDefault();
    // const userData = { data.email, data.pwd };
    // console.log(`I sent to the server: ${JSON.stringify(userData)}`);

    const resp = await loginUser(data);
    // console.log(resp);

    if (resp.state == 'SUCCESS') {
      const data = resp.data;
      setAuth(data);
      toast.success("Sikeres bejelentkezés!");
      // console.log(`The context was set: ${data.userID}`);
      nav('/');
    } else {
      // console.log(resp.mess);
      toast.error("A bejelentkezés sikertelen.\nKérjük ellenőrizze adatait!");
    }
  };

  return (
    <>
    <h1>Bejelentkezés</h1>
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitLoginForm)}>

        <div className="form-container">
          <div className="header">
            <h2></h2>
          </div>
          <div className="body">

              <FormInputUncontrolled
                id="email"
                key="email"
                name="email"
                type="text"
                label="E-mail cím"
                // required={true}
                options={{
                  required: "E-mail cím megadása kötelező!",
                  validate: (value) => {
                    // const re = new RegExp("^[\w\.\-]{2,20}@[\w\.\-]{2,20}\.[\w\-]{2,4}$");
                    if (!/^[\w\.\-]{2,20}@[\w\.\-]{2,20}\.[\w\-]{2,4}$/.test(value))
                      return "Nem megfelelő e-mail cím formátum!";
                    return true;
                  },
                }}
              />

              <FormInputUncontrolled
                id="pwd"
                key="pwd"
                name="pwd"
                type="password"
                label="Jelszó"
                // required={true}
                options={{
                  required: "Jelszó megadása kötelező!"
                }}
              />
          </div>

          <div className="footer">
            <div>
              <button className="submit">Bejelentkezés</button><br/><br/>
              Még nem tag? <Link to="/regisztracio">Regisztráció</Link>
              <InfoBubble
                text={
                <>
                  Segítség a regisztrációhoz:<br/> tekintse meg a videót&nbsp;
                  <a href="https://drive.google.com/file/d/119RBsFXXFxcijNSYV5b4jE2G-TMxb8M7/view?usp=drive_link">itt</a>!
                </>
                }
                bubbleColor="#f8f9fa"
                textColor="#343a40"
              />
            </div>
          </div>

        </div>
      </form>
    </FormProvider>
    </>
  );
}

export default LoginForm;