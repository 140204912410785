import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import Main from './components/Main';

export function UnderConstruction() {
    return (
        <p>Feltöltés alatt...</p>
    );
}

export default function App(){
    return (
        <BrowserRouter>
            <SideBar/>
            <div id='vertical-wrapper'>
                <NavBar/>
                {/* <div class="navbar">
                    <a href="#home">Home</a>
                    <a href="#news">News</a>
                    <div className="dropdown2">
                        <button className="dropbtn2">
                            Dropdown 
                        </button>
                        <div className="dropdown-content2">
                        <a href="#">Link 1</a>
                        <a href="#">Link 2</a>
                        <a href="#">Link 3</a>
                        </div>
                    </div> 
                </div> */}
                <Main/>
            </div>
        </BrowserRouter>
    );
}