import React, { useState } from 'react';
import './InfoBubble.css';
import { IoHelpCircleOutline } from "react-icons/io5";
const InfoBubble = ({ text, iconSize, iconColor, bubbleColor, textColor }) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  const bubbleStyle = {
    display: hover ? 'block' : 'none',
    backgroundColor: bubbleColor,
    color: textColor,
  };

  return (
    <div 
        className="info-bubble-container" 
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave}
    >
        <IoHelpCircleOutline
        style={{fontSize: '30px', color:'grey'}}/>
      <div className="info-bubble" style={bubbleStyle}>
        {text}
      </div>
    </div>
  );
};

InfoBubble.defaultProps = {
  iconSize: '1x',
  iconColor: '#000',
  bubbleColor: '#fff',
  textColor: '#000',
};

export default InfoBubble;
