import { apiBase } from '../util/constants';

export async function uploadFormationImg(data, formationId) {
    // console.log(data);
    const settings = {
      method: 'POST',
    // it's not necessary to set headers
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //   },
      body: data,
      credentials: 'include'
    };
    try {
        // TODO: set type (group, logo...) of the img as query param
        const fetchResponse = await fetch(`${apiBase}/upload/formations/${formationId}`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }    
  }

  export async function deleteFormationImg(imgId, imgName) {
    const settings = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(imgName),
      credentials: 'include'
    };
    try {
        console.log(imgName);
        const fetchResponse = await fetch(`${apiBase}/loggedinFormations/images/${imgId}`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }    
  }