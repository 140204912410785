import React, {useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import { IoLocationSharp } from 'react-icons/io5';
import { HiClock } from 'react-icons/hi';
import { GrNext } from 'react-icons/gr';

import AuthContext from '../../../context/AuthProvider';
import { AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";

export default function Event(props) {
  const { auth } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const month = ["január","február","március","április","május","június","július","augusztus","szeptember","október","november","december"];

  return (
    <>
      <div className='event-item' onClick={() => setShowModal(!showModal)}>
 {/*       <Link className='cards__item__link' to={props.path}> */}
            {/* <FormationPage show={showModal}/> */}
            {/* <div className='cards_item_pic-wrap'>
              <img
                  className='cards_item_img'
                  src={props.src}
              />
            </div> */}
        <div className="event-left">
          <div className="event-date">
            {/* <div className="year">{props.date.getFullYear()}</div> */}
            <div className="month">{month[props.date.getMonth()]}</div>
            <div className="date">{props.date.getDate()}</div>
          </div>
        </div>

        <div className="event-right">
          <h3 className="event-title">{props.title}</h3>

          <div className="event-props">
            {props.date.getHours() >= 7 ?
            <div className="event-timing">
              <HiClock/> &nbsp; {("0" + props.date.getHours()).slice(-2)}:{("0" + props.date.getMinutes()).slice(-2)}
            </div> : null }
            {props.place ?
            <div className="event-timing">
              <IoLocationSharp/> &nbsp; {props.place}
            </div> : null}
          </div>

          <div className="event-description">
            <p>{(props.text && (props.text).length < 150) ?
              <>
                {/* {props.text} */}
                <p dangerouslySetInnerHTML={{ __html: props.text }} />
                {
                  (props.files && props.files.map((f, index) => (
                    <>
                      <a href={f.fLink} target="_blank" key={index} rel="noreferrer">{f.fDescription}</a>
                      {props.files.length > 1 && index != props.files.length-1 ? ', ' : null}
                    </>
                  )))
                }
              </> :
              <>
                {props.text ? 
                <>
                  {/* {props.text.substring(0, 150)}... */}
                  <p dangerouslySetInnerHTML={{ __html: props.text.substring(0, 150)+'...' }} />
                </> : null}
              </>
              }
            </p>

            {
              (auth?.roleName === "admin") ?
              <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '15px'}}>
                <div>
                  <button className="submit"><AiOutlineEdit/> Szerkesztés</button>
                  &nbsp;&nbsp;
                  <button className="submit"><AiOutlineDelete/> Törlés</button>
                </div>
                <Link to={'/esemenyek/' + props.id}>Tovább <GrNext/></Link>
              </div> :
              <p style={{textAlign: 'right'}}>
                <Link to={'/esemenyek/' + props.id}>Tovább <GrNext/></Link>
              </p>
            }

          </div>

        </div>
 {/*       </Link>*/}
      </div>
    </>
  );
}