import React, { useState, useEffect } from 'react';
import './Formations.css';
import Formation from './Formation';
import { getAllFormationData } from '../../../service/formation';
import toast from 'react-hot-toast';
import { apiBase } from '../../../util/constants';
import FormationFilter from '../../forms/elementary/FormationFilter';

export default function Formations() {
  const [allFormations, setAllFormations] = useState([]);
  const [filteredFormations, setFilteredFormations] = useState([]);

  // for filtering
  const [filterInput, setFilterInput] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const filterChars = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];

  const setUpperFilterInput = (newFilterInput) => {
    setFilterInput(newFilterInput);
  };

  const setUpperSelectedOpt = (newOptions) => {
    setSelectedOptions(newOptions);
    // console.log(newOptions);
  };

  useEffect(async () => {
    const respGetAllFormations = await getAllFormationData();

    if (respGetAllFormations.state == "SUCCESS") {
      setAllFormations(respGetAllFormations.mess);
      setFilteredFormations(respGetAllFormations.mess);
    }
    else
      toast.error("Az együttesek adatait nem sikerült betölteni.");
  }, []);

  const filterFormations = (e) => {
    e.preventDefault();
    // console.log(filterInput);


    setFilteredFormations(
      allFormations.filter(val => {
        if ((filterInput == "") || (val.fName.toLowerCase().includes(filterInput.toLowerCase())))
          return val;
        }
      ).filter(val2 => {
        if ((selectedOptions.length == 0) || (selectedOptions.includes(val2.fType))){
          return val2;
        }
      })
    );
  };

  return (
    <>
        <h1>Együttesek</h1>
        {/* <Link to={"/adatlap"} state={profileData}>
          Adatlap szerkesztése
        </Link> */}


          <div className='filter-container'>
            <div className='filter-content'>
              {/* {
                filterChars.map(c => 
                  // <a href={`${c}char`}>{c}</a>
                  <Link to={`#${c}_char`}>
                    <span style={{paddingRight: "20px"}}>{c}</span>
                  </Link>
                )
              } */}
              <FormationFilter 
                filterInput={filterInput}
                setUpperFilterInput={setUpperFilterInput}
                setUpperSelectedOpt={setUpperSelectedOpt}
              />

              <button className='btn primary' onClick={filterFormations}>Keresés</button>
            </div>
          </div>

        <div className='cards_container'>
          {
            filteredFormations.length > 0 ?
              <ul className='cards_items'>
                {
                  filteredFormations.map((fData) => {
                    let imgLink = fData.img ? `${apiBase}/uploads/formations/${fData.img}` : "img/noimg.webp";
                    return(
                      <Formation
                        key={fData.fId}
                        src={imgLink}
                        text={fData.fName}
                        path={"/egyuttesek/" + fData.fId}
                      />
                    );
                  })
              }
              </ul> : <p>Nincsenek megjelenítendő együttesek.</p>
          }
          {/* {
            filteredFormations.length > 0 ? 
              filterChars.map(c => {
                const filtered = filteredFormations.filter(f => f.fName[0].toUpperCase() == c);
                return filtered.length > 0 ? 
                  <>
                    <span id={`${c}_char`}>{c}</span>
                    <hr/>
                    <ul id={c} className='cards_items'>
                    {
                      filtered.map((fData) => {
                        let imgLink = fData.img ? `${apiBase}/uploads/formations/${fData.img}` : "img/noimg.webp";
                        return(
                          <Formation
                            key={fData.fId}
                            src={imgLink}
                            text={fData.fName}
                            path={"/egyuttesek/" + fData.fId}
                          />
                        );
                      })
                    }
                    </ul> 
                  </> : null;
                
              }) : <p>Nincsenek megjelenítendő együttesek.</p>
          } */}
        </div>
    </>
  );
}