import React, { useEffect, useState } from 'react';
import { initialAddress, initialAddressValidity } from '../../../util/initialFormData';
import FormInput from './FormInput';
import FormInputUncontrolled from './FormInputUncontrolled';
import './AddressAutocomplete.css';
 
// enable upper- and lowercase letters with diacritics: [A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]
function AddressAutocompleteCopy(props) {
  const addressInputFields = [
    {
      id: `country${props.entity}`,
      type: "text",
      name: "country",
      label: "Ország",
      cls: "full-width-group",
      options: {
        required: "A mező kitöltése kötelező!",
        validate: (value) => {
            if (!/^[A-Z\u00C0-\u00DC][A-Z\u00C0-\u00DCa-z\u00E0-\u00FC\. \-]{1,31}$/.test(value))
              return "Hibásan megadott országnév!";
            return true;
          },

      },
      required: true
    },
    {
      id: `postcode${props.entity}`,
      type: "text",
      name: "postcode",
      label: "Irányítószám",
      cls: "none",
      options: {
        required: "A mező kitöltése kötelező!",
        validate: (value) => {
            if (!/^[a-zA-Z0-9][a-zA-Z0-9 ]{0,31}$/.test(value))
              return "Hibásan megadott postakód!";
            return true;
          },
    
      },
      required: true
    },
    {
      id: `city${props.entity}`,
      type: "text",
      name: "city",
      label: "Település",
      cls: "quarter-right-group",
      options: {
        required: "A mező kitöltése kötelező!",
        validate: (value) => {
            if (!/^[A-Z\u00C0-\u00DC][A-Z\u00C0-\u00DCa-z\u00E0-\u00FC\. \(\)\-]{1,31}$/.test(value))
              return "Hibásan megadott település!";
            return true;
          },
    
      },
      required: true
    },
    {
      id: `street${props.entity}`,
      type: "text",
      name: "street",
      label: "Utca",
      cls: "quarter-left-group",
      options: {
        required: "A mező kitöltése kötelező!",
        validate: (value) => {
            if (!/^[A-Z\u00C0-\u00DC][A-Z\u00C0-\u00DCa-z\u00E0-\u00FC-\. 0-9]{1,31}$/.test(value))
              return "Hibásan megadott utcanév!";
            return true;
          },
    
      },
    //   regex: "^[A-Z\u00C0-\u00DC][A-Z\u00C0-\u00DCa-z\u00E0-\u00FC-\. 0-9]{1,31}$",
    //   errorMessage: "Hibás utca formátum!",
      required: true
    },
    {
      id: `housenumber${props.entity}`,
      type: "text",
      name: "housenumber",
      label: "Házszám",
      cls: "none",
      options: {
        required: "A mező kitöltése kötelező!",
        validate: (value) => {
            if (!/^[0-9][a-zA-Z0-9\. \/\,\-]{0,31}$/.test(value))
              return "Hibásan megadott házszám!";
            return true;
          },
    
      },
    //   regex: "^[0-9][a-zA-Z0-9\.,-/ ]{0,31}$",
    //   errorMessage: "Hibás házszám formátum!",
      required: true
    },
    {
      id: `building${props.entity}`,
      type: "text",
      name: "building",
      label: "Épület",
      cls: "none",
      options: {
        validate: (value) => {
            if (!/^([a-zA-Z0-9][a-zA-Z0-9\.\/ \,\-]{0,31})*$/.test(value))
              return "Hibásan megadott épület!";
            return true;
          },
    
      },
    //   regex: "^[a-zA-Z0-9][a-zA-Z0-9\.,-/ ]{0,31}$",
    //   errorMessage: "Hibás épület formátum!",
      required: false
    },
    {
      id: `stairway${props.entity}`,
      type: "text",
      name: "stairway",
      label: "Lépcsőház",
      cls: "none",
      options: {
        validate: (value) => {
            if (!/^([a-zA-Z0-9][a-zA-Z0-9\.\/ \,\-]{0,31})*$/.test(value))
              return "Hibásan megadott lépcsőház!";
            return true;
          },
    
      },
    //   regex: "^[a-zA-Z0-9][a-zA-Z0-9\.,-/ ]{0,31}$",
    //   errorMessage: "Hibás lépcsőház formátum!",
      required: false
    },
    {
      id: `floor${props.entity}`,
      type: "text",
      name: "floor",
      label: "Emelet",
      cls: "none",
      options: {
        validate: (value) => {
            if (!/^([a-zA-Z0-9][a-zA-Z0-9\.\, \/\-]{0,31})*$/.test(value))
              return "Hibásan megadott emelet!";
            return true;
          },
    
      },
    //   regex: "^[a-zA-Z0-9][a-zA-Z0-9\.,-/ ]{0,31}$",
    //   errorMessage: "Hibás emelet formátum!",
      required: false
    },
    {
      id: `apartment${props.entity}`,
      type: "text",
      name: "apartment",
      label: "Lakrész",
      cls: "none",
      options: {
        validate: (value) => {
            if (!/^([a-zA-Z0-9][a-zA-Z0-9\. \/\,\-]{0,31})*$/.test(value))
              return "Hibásan megadott lakrész!";
            return true;
          },
      },
    //   regex: "^[a-zA-Z0-9][a-zA-Z0-9\.,-/ ]{0,31}$",
    //   errorMessage: "Hibás lakrész formátum!",
      required: false
    },
    {
      id: `additional${props.entity}`,
      type: "text",
      name: "additional",
      label: "Kiegészítés",
      cls: "full-width-group",
      options: {
        validate: (value) => {
            if (!/^([A-Z\u00C0-\u00DCa-z\u00E0-\u00FC][A-Z\u00C0-\u00DCa-z\u00E0-\u00FC\.\,\/ \-]{0,31})*$/.test(value))
              return "Hibásan megadott kiegészítő szöveg!";
            return true;
          },
      },
    //   regex: "^[A-Z\u00C0-\u00DCa-z\u00E0-\u00FC][A-Z\u00C0-\u00DCa-z\u00E0-\u00FC-\.,/ ]{0,31}$",
    //   errorMessage: "Hibás a kiegészítő szöveg formátuma!",
      required: false
    }
  ];

    const {address, onAddressChange} = props;
    const [fullAddress, setFullAddress] = useState(initialAddress);

  return (
    <div className="full-width-group" style={props.entity !== "Event" ? {marginTop: "50px"} : {}}>
    {(props.entity !== "Event") ? <h3>Postai cím</h3> : null}
        <div className='address-form'>
        {/* <div className="formInput">
          <label htmlFor="addressAutocomplete">Postai cím</label>
        </div> */}
        {/* TODO: check why API key is invalid */}
          {/* <GeoapifyContext apiKey={process.env.REACT_APP_API_KEY}>
              <GeoapifyGeocoderAutocomplete 
                  placeholder="Kezdje el gépelni..."
                  // type={type}
                  id={"addressAutocomplete" + props.entity}
                  name="addressAutocomplete"
                  lang="ro"
                  // position={position}
                  // countryCodes={countryCodes}
                  // limit={limit}
                  // value={displayValue}
                  placeSelect={onPlaceSelect}
                  // suggestionsChange={onSuggestionChange}
              />
          </GeoapifyContext> */}

        {
            (props.entity === "Event") ? 
            addressInputFields.filter(filteredInput => ["city", "country", "postcode", "street", "housenumber", "additional"].includes(filteredInput.name))
            .map((filteredInput) => (
              <div className={filteredInput.cls}>
                <FormInputUncontrolled
                  key={filteredInput.name}
                  {...filteredInput}
                />
              </div>
            ))
            :
            addressInputFields.map((input) => (
              <div className={input.cls}>
                <FormInputUncontrolled
                  key={input.name}
                  {...input}
                />
              </div>
          ))
        }
      </div>
    </div>
  );
}

export default AddressAutocompleteCopy;
