import React, { useEffect, useState, useContext, useCallback } from "react";
import AuthContext from "../../context/AuthProvider";
import { useParams } from "react-router-dom";
import {initialFormationData, initialOfficialNames, initialAddress} from "../../util/initialFormData";
import AddressAutocompleteCopy from "./elementary/AddressAutocompleteCopy";
import "./BasicDataUpdateForm.css";
import FormInputUncontrolled from "./elementary/FormInputUncontrolled";
import MyDropdown from "./elementary/MyDropdown";
import FormationCategoryDropdown from "./elementary/FormationCategoryDropdown";
import { getFormationBasicData, updateFormationBasicData } from "../../service/formation";
import { updateUserBasicData } from "../../service/user";
import UserData from "./elementary/UserData";
import { contextType } from "react-quill";
import toast from "react-hot-toast";
import { useForm, FormProvider, Controller } from "react-hook-form";
import UserDataWrapper from "./elementary/UserDataWrapper";

// INPUT FIELDS

const formationDataFormItems = [
  {
    id: "fformationName",
    name: "formationName",
    label: "Formáció neve",
    type: "text",
    options: {
      required: "A mező kitöltése kötelező!",
      validate: (value) => {
        if (!/^[0-9A-Z\u00C0-\u00DC][0-9A-Z\u00C0-\u00DCa-z\u00E0-\u00FC0-9\#\@\. \-]{1,31}$/.test(value))
          return "Hibásan megadott formációnév!";
        return true;
      },
    },
    required: true,
  },
  {
    id: "ffoundationYear",
    name: "foundationYear",
    label: "Alapítás éve",
    type: "text",
    options: {
      required: "A mező kitöltése kötelező!",
      validate: (value) => {
        if (!/^[1-9][0-9]{3}$/.test(value))
          return "Hibás évszám!";
        return true;
      },
    },
    required: true,
  },
];

const officialFormationFormItems = [
  {
    id: "formationNameHu",
    name: "huName",
    label: "Hivatalos név magyarul",
    type: "text",
    options: {
      required: "A mező kitöltése kötelező!",
      validate: (value) => {
        if (!/^[0-9A-Z\u00C0-\u00DC][0-9A-Z\u00C0-\u00DCa-z\u00E0-\u00FC0-9\#\@\. \-]{1,31}$/.test(value))
          return "Hibásan megadott formációnév!";
        return true;
      },
    },
    required: true,
  },
  {
    id: "formationNameRo",
    name: "roName",
    label: "Hivatalos név románul",
    type: "text",
    options: {
      required: "A mező kitöltése kötelező!",
      validate: (value) => {
        if (!/^[0-9A-Z\u00C0-\u00DC][0-9A-Z\u00C0-\u00DCa-z\u00E0-\u00FC0-9\#\@\. \-]{1,31}$/.test(value))
          return "Hibásan megadott formációnév!";
        return true;
      },
    },
    required: true,
  }
];

function BasicDataUpdateForm(props){
  const {reset, control, ...methods} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {...initialFormationData, ...initialOfficialNames, ...initialAddress}
  });

  const resetFormationDV = useCallback(async (result) => {
    // OFFICIAL NAMES

    let officialNames = {...initialOfficialNames};
    const formationOfficialNamesFetch = result.mess.officialFormationNames;

    if (formationOfficialNamesFetch) {
      formationOfficialNamesFetch.map((fName) => {
        // console.log(fName.aliasname_formation);
        // console.log(fName.name_lang);
        officialNames[fName.name_lang + "Name"] = fName.aliasname_formation;
      });
    }

    // ADDRESS
    const officialAddressFetch = result.mess.officialFormationAddress;
    let officialAddress = {...initialAddress};

    if (officialAddressFetch) {
      officialAddress = {
        city: officialAddressFetch[0]?.city || initialAddress.city,
        country: officialAddressFetch[0]?.country || initialAddress.country,
        postcode: officialAddressFetch[0]?.postcode || initialAddress.postcode, 
        street: officialAddressFetch[0]?.street || initialAddress.street,
        housenumber: officialAddressFetch[0]?.nr || initialAddress.housenumber,
        /* no-autofill attributes */
        building: officialAddressFetch[0]?.building || initialAddress.building,
        stairway: officialAddressFetch[0]?.stairway || initialAddress.stairway,
        floor: officialAddressFetch[0]?.floor || initialAddress.floor,
        apartment: officialAddressFetch[0]?.apartment || initialAddress.apartment,
        additional: officialAddressFetch[0]?.additional || initialAddress.additional
      };
    }

    const finalData = {
      formationName: result.mess.formationBasicData[0]?.name_formation || initialFormationData.formationName,
      foundationYear: result.mess.formationBasicData[0]?.year_foundation || initialFormationData.foundationYear,
      // TODO: if it's controlled component
      // subCategory: initialFormationData.subCategory,

      ...officialNames,
      ...officialAddress
    };

    reset(finalData); // asynchronously reset your form values

  }, [reset]);

  // function handleCategoryChange(selectedOption) {
  //   setFSubCategory(selectedOption);
  //   console.log(selectedOption);
  // }

  const { auth } = useContext(AuthContext);

  const [fSubCategory, setFSubCategory] = useState(initialFormationData.subCategory);
  const [isProfessionalFormation, setIsProfessionalFormation] = useState(initialFormationData.isProfessionalFormation);

  // egyetlen kapcsolattartó lehet
  const [regUser, setRegUser] = useState(null); // egy elem a listában
  const [contactUser, setContactUser] = useState(null); // contact_person = true
  const [leaderUser, setLeaderUser] = useState(null); // id_frole = 4 <- karnagy

  // CP checkbox value change
  function handleCPChange(event, entity) {
    if (entity == "")
      setRegUser({...regUser,
        contact_person: event.target.checked
      });
    else if (entity == "CP")
      setContactUser({...contactUser,
        contact_person: event.target.checked
      });  
    else if (entity == "Leader")
      setLeaderUser({...leaderUser,
        contact_person: event.target.checked
      });
  };


  // user role dropdown value change
  function handleUserRoleChange(event, entity) {
    if (entity == "")
      setRegUser({...regUser,
        id_frole: event.target.value
      });
    else if (entity == "CP")
      setContactUser({...contactUser,
        id_frole: event.target.value
      });  
  };

  // useEffect(async () => {
  //   const allData = await getFormationBasicData(auth.formationId);
  //   console.log(`My id is: ${auth.formationId}`);
  //   console.log('From db:', allData.mess);
  // });

  useEffect(async () => {
    const result = await getFormationBasicData(auth.formationId);
    console.log(result.mess);

    // set formation data in the form

    resetFormationDV(result);
    setIsProfessionalFormation(result.mess.formationBasicData[0]?.is_professional_formation || initialFormationData.isProfessionalFormation);
    setFSubCategory(result.mess.formationBasicData[0] ? {value: result.mess.formationBasicData[0].id_fstype, label: result.mess.formationBasicData[0].name_fstype} : initialFormationData.subCategory);

    //////////////////////////////
    // reg, CP, Leader person? //
    /////////////////////////////

    const formationUsers = result.mess.formationUsersData;

    formationUsers.map((user) => {
      if (formationUsers.length == 1) {
        let checkboxVal = Boolean(user.contact_person);
        setRegUser({...user, 'contact_person': checkboxVal});
      }
      else {
        if (user.id_frole == 4)
          setLeaderUser(user);
        else {
          if (user.contact_person)
            setContactUser(user);
          else 
            setRegUser(user);
        }
      }

      // ha a regisztralo szemely karnagy is egyben
      if (!regUser && leaderUser) {
        setRegUser(leaderUser);
        setLeaderUser(null);
      }
     });

  }, [resetFormationDV]);

  async function testSubmit(data) {
    const organizedData = {
      formationName: data.formationName, 
      foundationYear: data.foundationYear, 
      isProfessionalFormation: isProfessionalFormation,
      // TODO: connect with dropdown
      subCategory: fSubCategory,
      address: {
        city: data.city,
        country: data.country,
        postcode: data.postcode, 
        street: data.street, 
        housenumber: data.housenumber, 
        building: data.building, 
        stairway: data.stairway,
        floor: data.floor, 
        apartment: data.apartment, 
        additional: data.additional, 
      },
      officialFormationNames: {
        huName: data.huName,
        roName: data.roName
      }
    };

    const formationDataResp = await updateFormationBasicData(auth.formationId, organizedData);
    if (formationDataResp.state == 'SUCCESS')
      toast.success("A formáció adatait sikeresen mentettük!");
    else
      toast.error("A formáció adatainak mentése nem sikerült.");

    // console.log(organizedData);

  };

  return (
    <>
      <h1>Adatok módosítása</h1>

      <FormProvider {...methods}>

        <div className="form-window">
          <div className="form-window-header">
            <h2>Formáció alapadatai</h2>
          </div>
          <form className="user-data-page" onSubmit={methods.handleSubmit(testSubmit)}>
            {/* Fontos megjegyezni, hogy ezek nem nyilvános adatok:
              Formáció neve
              Alapítás éve
              Besorolás
              Bejegyzett kórus? -> Hivatalosan bejegyzett név (magyarul, románul), Postai cím
            */}

            {
              formationDataFormItems.map((input) => (
                  <FormInputUncontrolled
                  key={input.id}
                  {...input}
                  />
              ))
            }
            
            {/* TODO: use FormInputControlled somehow */}
            <div className="full-width-group">
            
              <div className="formInput">
                  <label htmlFor="subCategory">
                    Szakosztály kategória<span style={{color:'red'}}>*</span>
                  </label>
                  <FormationCategoryDropdown
                    id="subCategory"
                    value={fSubCategory}
                    name="subCategory"
                    onChange={setFSubCategory}
                  />
              </div>


              <div className="formInput">
                <label htmlFor="isProfessionalFormation">Bejegyzett kórus?</label>
                <input type="checkbox" id="isProfessionalFormation" name="isProfessionalFormation"
                  checked={isProfessionalFormation} 
                  onChange={() => setIsProfessionalFormation(!isProfessionalFormation)}
                />
              </div>

            </div>


            {isProfessionalFormation ?
              <>
                <div className="form-window-header full-width-group">
                  <h2>Bejegyzett formáció adatai</h2>
                </div>

                {officialFormationFormItems.map((input) => (
                    <FormInputUncontrolled
                    key={input.id}
                    {...input}
                    />
                ))}

                <AddressAutocompleteCopy
                  // address={this.state.formationData.address} 
                  // onAddressChange={this.handleFormationAddressChange}
                  entity="Formation"/>
              </> : null
            }


              <button className="btn submit full-width-group">Mentés</button>
          </form>
        </div>

      </FormProvider>

      <div className="form-window">
        <div className="form-window-header">
          <h2>Regisztráló személy adatai</h2>
        </div>
        <UserDataWrapper 
          entity=""
          defaultValues={regUser}
          handleCPChange={handleCPChange}
          handleUserRoleChange={handleUserRoleChange}
        />
      </div>

      {
        (!regUser?.contact_person && !leaderUser?.contact_person) ?
        <div className="form-window">
          <div className="form-window-header">
            <h2>Kapcsolattartó személy adatai</h2>
          </div>
          <UserDataWrapper 
            entity="CP" 
            defaultValues={contactUser}
            handleCPChange={handleCPChange}
            handleUserRoleChange={handleUserRoleChange}
          />
        </div> : null
      }

      {
        (regUser?.id_frole != 4 && contactUser?.id_frole != 4) ?
        <div className="form-window">
          <div className="form-window-header">
            <h2>Karnagy adatai</h2>
          </div>

          <UserDataWrapper 
            entity="Leader" 
            defaultValues={leaderUser}
            handleCPChange={handleCPChange}
            handleUserRoleChange={handleUserRoleChange}
          />
        </div> : null
      }

    </>
  );
}
  
export default BasicDataUpdateForm;
