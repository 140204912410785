import { apiBase } from '../util/constants';

export async function sendEmail(data) {
    const settings = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };
    try {
        const fetchResponse = await fetch(`${apiBase}/sendEMail`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }    
  }