import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import {updatePwd, linkValidityCheck, delValidationRequest} from '../../service/user';
import RedirectPage from '../RedirectPage';
import FormInput from './elementary/FormInput';
import { Link } from "react-router-dom";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const NewPassword = () => {
    const { userID, key } = useParams();
    const nav = useNavigate();

    const [showPwd, setShowPwd] = useState(false);
    const toggleShowPassword = () => {
      setShowPwd(!showPwd);
    };
  
    const [state, setState] = useState({
      pwd: '',
      pwdErrors: {
        pwdLen: false,
        pwdSimpleChars: false,
        pwdSpecialChars: false,
      },
      pwdAgain: '',
      verifData: false,
    });
  
    useEffect(() => {
      const fetchData = async () => {
        const getVerifData = await linkValidityCheck(userID, key);
        setState((prevState) => ({ ...prevState, verifData: getVerifData }));
      };
  
      fetchData();
    }, [userID, key]);
  
    const onPwdChange = (e) => {
      const typedText = e.target.value;
  
      setState((prevState) => ({
        ...prevState,
        pwd: typedText,
        pwdErrors: {
          ...prevState.pwdErrors,
          pwdLen: typedText.length >= 6,
          pwdSimpleChars: /.*[A-Z].*/.test(typedText) && /.*[a-z].*/.test(typedText),
          pwdSpecialChars: /.*[0-9@$!%*#?&].*/.test(typedText),
        },
      }));
    };
  
    const onPwdAgainChange = (e) => {
      setState((prevState) => ({ ...prevState, pwdAgain: e.target.value }));
    };
  
    const submitPwd = async (e) => {
      e.preventDefault();
  
      const pwdDate = { userID, pwd: state.pwd };
      const pwdStatus = await updatePwd(pwdDate);
  
      if (pwdStatus.state === "SUCCESS") {
        await delValidationRequest({ userID });
        // TODO: Redirect or show success message
        nav('/landing');
      }
  
    //   console.log(pwdStatus.mess);
    };
  
    return (
      <>
        {state.verifData ? (
  <>
                <h1>Jelszó beállítása</h1>
                <form onSubmit={submitPwd}>
                <div className="form-container">

                    <div className="header">
                        <h2></h2>
                    </div>
                    <div className="body">
                        <div className="formInput">
                            <label htmlFor="pwd">Jelszó</label>
                            {
                              <span className="icons" onClick={toggleShowPassword}>
                                { showPwd ? 
                                  <IoMdEye style={{color: "#18ab42"}}/> : 
                                  <IoMdEyeOff style={{color: "grey"}}/>
                                }
                              </span>
                            }
                            <input id="pwd" name="pwd" type={showPwd ? "text" : "password"}
                            value={state.pwd} onChange={onPwdChange}/>
                        </div>
                        {/* <br/>
                        <input id="pwdAgain" name="pwdAgain" type="password" placeholder="Jelszó újra" 
                        value={this.state.pwdAgain} onChange={this.onPwdChange}/>
                        <br/> */}
                        {/* <FormInput
                            key="pwd"
                            name="pwd"
                            type="password" 
                            label="Jelszó" 
                            value={this.state.pwd} 
                            onChange={this.onPwdChange}
                        /> */}

                        <FormInput
                            key="pwdAgain"
                            name="pwdAgain"
                            type="password" 
                            label="Jelszó újra"
                            value={state.pwdAgain} 
                            onChange={onPwdAgainChange}
                            regex={`^${state.pwd}$`}
                            errorMessage="A két jelszó különbözik!"
                        /><br/>
                        <div className="pwd-validator">A megadott jelszónak teljesítenie kell a következő feltételeket:<br/>
                            <ul>
                                {/* <li> */}
                                <li style={{color: (state.pwdErrors.pwdLen ? "green" : "black") }}>
                                    Legalább 6 karakter hosszú
                                </li>
                                {/* <li> */}
                                <li style={{color: (state.pwdErrors.pwdSimpleChars ? "green" : "black") }}>
                                    Legalább egy nagybetűt és egy kisbetűt tartalmaz
                                </li>
                                {/* <li> */}
                                <li style={{color: (state.pwdErrors.pwdSpecialChars ? "green" : "black") }}>
                                    Legalább egy számjegyet vagy <br/>speciális karaktert (@$!%*#?&) tartalmaz
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="footer">
                        <div>
                            <button className="submit">Mentés</button>
                        </div>
                    </div>
                
                </div>
                </form>
            </>
        ) : (
          <RedirectPage title="Hiba" text="Ez a link lejárt vagy nem létezik." />
        )}
      </>
    );
  };
  
  export default NewPassword;