import React, { Component, useEffect, useState } from 'react';
import { default as ReactSelect, components } from "react-select";
import { getAllFormationTypes } from '../../../service/formation';
import FormInput from './FormInput';
import './FormationFilter.css';
import {customDropdownStyle} from "../../../util/globalStyles";


const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  
  function FormationFilter(props) {
    const [types, setTypes] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);  

    const handleChange = (selected) => {
      setSelectedOptions(selected);
      props.setUpperSelectedOpt(selected.map(val => val.value));
    };

    const onFilterInputChange = (e) => {
      // setFilterInput(e.target.value);
      props.setUpperFilterInput(e.target.value);
    };

    useEffect(async () => {
      const typesResp = await getAllFormationTypes();
      if (typesResp.state == 'SUCCESS') {
  
          const formationCategories = [];
          typesResp.mess.map((cat) => {

              if (!formationCategories.hasOwnProperty(cat.id_fmtype)) {
                  formationCategories[cat.id_fmtype] = {};
                  formationCategories[cat.id_fmtype].label = cat.name_fmtype;
                  formationCategories[cat.id_fmtype].value = cat.id_fmtype;
                  formationCategories[cat.id_fmtype].options = [];
              }

              if (cat.id_fstype)
                  formationCategories[cat.id_fmtype].options.push({value: cat.id_fstype, label: cat.name_fstype});
              else {
                  delete formationCategories[cat.id_fmtype].options;
                  formationCategories[cat.id_fmtype].value = cat.id_fmtype;
              }
          });  

        setTypes(formationCategories);
    }}, []);
  
    return (
      <>
        <FormInput
          key="filterInput"
          name="filterInput"
          type="text"
          label={null}
          placeholder="Név"
          value={props.filterInput}
          onChange={onFilterInputChange}
        />
        <ReactSelect
            options={types}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option
            }}
            onChange={handleChange}
            value={selectedOptions}
            placeholder="Kategória"
            styles={customDropdownStyle}
        />
      </>
    );
  }
  
  export default FormationFilter;
  

