import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { IoLocationSharp } from 'react-icons/io5';
import { IoCalendarSharp } from 'react-icons/io5';
import { HiClock } from 'react-icons/hi';


export default function UpcomingEvent(props) {
//   const [showModal, setShowModal] = useState(false);
  const month = ["január","február","március","április","május","június","július","augusztus","szeptember","október","november","december"];

  return (
    <>
      <Link to={'/esemenyek/' + props.id}>
      {/* {props.title} */}
        <div className='upcoming-event-item'>

          {
            (props.img) ?
            <img className='upcoming-event-img' src={props.img.img}/> :
            <div className='upcoming-event-placeholder'>
              <div className='title-text'>{props.title}</div>
            </div>
          }
          <div className="overlay">
            <div className="text">
              <h3 className="event-title">{props.title}</h3>
              <IoCalendarSharp/>&nbsp;
              {props.date.getFullYear()}.&nbsp;
              {("0" + (props.date.getMonth() + 1)).slice(-2)}.&nbsp;
              {props.date.getDate()}.
              <br/>

              {props.date.getHours() >= 7 ?
              <>
                <HiClock/>&nbsp;
                {("0" + props.date.getHours()).slice(-2)}:{("0" + props.date.getMinutes()).slice(-2)}
                <br/>
              </> : null }

              {props.place.length < 50 ?  
                <>
                  <IoLocationSharp/>&nbsp;{props.place}
                </> : null}

            </div>
          </div>

          </div>
        </Link>
    </>
  );
}