import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = (props) => {
    const {getText, text} = props;
    // const [value, setValue] = useState('');
    
    // TODO: https://github.com/quilljs/quill/issues/3806
    return <ReactQuill theme="snow" onChange={getText} value={text} />;
};

export default TextEditor;