export const customDropdownStyle = {
    control: (base, state) => ({
      ...base,
      border: '0.25px solid #535e5a',
      borderRadius: '5px',
      width: '100%',
      boxSizing: 'border-box',
      height: '50px',
      fontSize: '15px',
      fontFamily: 'arial, verdana',
      transition: '0.3s ease',
      outline: state.isFocused || state.isHovered ? '0.5px solid #04AA6D' : 'none',
      boxShadow: state.isFocused ? '0 0 0 1px #04AA6D' : 'none',
      '&:hover': {
        outline: '0.5px solid #04AA6D',
      },
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '5px',
      marginTop: '0',
      fontFamily: 'arial, verdana',
    }),
    option: (base, state) => ({
      ...base,
      fontSize: '15px',
      fontFamily: 'arial, verdana',
      backgroundColor: state.isFocused ? '#e9f7ef' : 'white',
      color: state.isSelected ? '#04AA6D' : 'black',
      '&:active': {
        backgroundColor: '#ccead0',
      },
    }),
  };