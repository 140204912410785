import FormInput from "./FormInput";
import FormInputUncontrolled from "./FormInputUncontrolled";
import MyDropdown from "./MyDropdown";
import { initialUserDValidity, initialUserData } from "../../../util/initialFormData";
import React, { Component } from 'react';

class UserDataCopy extends Component {
    constructor(props) {
      super(props);
  
      // props: userType, values, onValuesChange
      this.props = props;
      // console.log('Values from parent: ', props.values, ' ', props.userType);

      // this.state = {
      //   formValidity: initialUserDValidity(this.props.userType)
      // }
    }
  
    render() {
      const input_fields = [
          {
              id: `lastName${this.props.userType}`,
              name: "lastName",
              type: "text",
              errorMessage: "Hibás vezetéknév formátum!",
              label: "Vezetéknév",
              options: {
                required: "A mező kitöltése kötelező!",
                validate: (value) => {
                    if (!/^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$/.test(value))
                      return "Hibás vezetéknév formátum!";
                    return true;
                  },
        
              },
              // regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
              required: true,
          },
          {
              id: `firstName${this.props.userType}`,
              name: "firstName",
              type: "text",
              errorMessage: "Hibás keresztnév formátum!",
              label: "Keresztnév",
              options: {
                required: "A mező kitöltése kötelező!",
                validate: (value) => {
                    if (!/^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$/.test(value))
                      return "Hibás keresztnév formátum!";
                    return true;
                  },
        
              },
              // regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
              required: true,
          },
          {
              id: `email${this.props.userType}`,
              name: "email",
              type: "text",
              errorMessage: "Nem megfelelő e-mail cím formátum! Példa: pelda@valami.ro",
              label: "E-mail cím",
              options: {
                required: "A mező kitöltése kötelező!",
                validate: (value) => {
                    if (!/^[\\\w\.\-]{2,15}\@[\\\w\.\-]{2,10}\.[\\\w\-]{2,4}$/.test(value))
                      return "Nem megfelelő e-mail cím formátum! Példa: pelda@valami.ro";
                    return true;
                  },
        
              },
              // regex: "^[\\\w\\.\\-]{2,20}@[\\\w\\.\\-]{2,20}\\.[\\\w\\-]{2,4}$",
              required: true,
          },
          {
              id: `telNum${this.props.userType}`,
              name: "telNum",
              type: "text",
              errorMessage: "Nem megfelelő telefonszám formátum!",
              label: "Telefonszám",
              options: {
                required: "A mező kitöltése kötelező!",
                validate: (value) => {
                    if (!/^[\+]?[\(]?[0-9]{3}[\)]?[\s\.\-]?[0-9]{3}[\s\.\-]?[0-9]{4,6}$/.test(value))
                      return "Nem megfelelő telefonszám formátum!";
                    return true;
                  },
        
              },
              // regex: "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
              required: true,
          },
      ];
  
        return (
          <>
            {input_fields.map((input) => (
                <FormInputUncontrolled
                  key={input.id}
                  {...input}
                  // value={this.props.values[input.name]}
                  // onChange={this.userDataChange}
                />
            ))}
      
              <div className="full-width-group">
                {this.props.userType !== 'Leader' ? (
                  <div className="formInput">
                    <label htmlFor="selectedRole">
                      Titulus<span style={{color:'red'}}>*</span>
                    </label>
                    {/* TODO: state change of the dropdown updates the whole form value */}
                    <MyDropdown
                      name="selectedRole"
                      id={`selectedRole${this.props.userType}`}
                      value={this.props.fetchedValues.selectedRole}
                      onChange={(event) => this.props.handleUserRoleChange(event, this.props.userType)}
                    />
                  </div>
                ) : null}
              </div>
        
                {this.props.userType !== 'CP' ? (
                  <div className="formInput">
                    <label htmlFor="contactPerson">Ő a kapcsolattartó személy?</label>
                    <input
                      type="checkbox"
                      id={`contactPerson${this.props.userType}`}
                      name="contactPerson"
                      checked={this.props.fetchedValues.contactPerson}
                      onChange={(event) => this.props.handleCPChange(event, this.props.userType)}
                    />
                  </div>
                ) : null}
        </>
        );
    }
}
      
    UserDataCopy.defaultProps = {
        userType: "",
    };

    export default UserDataCopy;