import React, {useContext} from 'react';
import AuthContext from "../../../context/AuthProvider";
import {logoutUser} from '../../../service/user';
import { useNavigate } from 'react-router-dom';
import { CgLogOut } from "react-icons/cg";

function Logout() {
    const { auth, setAuth } = useContext(AuthContext);
    const nav = useNavigate();

    async function logOut() {
        // if someone is logged in
        if (Object.keys(auth).length !== 0) {
            const resp = await logoutUser();
            if (resp.state == 'SUCCESS') {
                nav("/");
                setAuth(null);
            }
            console.log(resp.mess);
        }
    }

    return (
        <a onClick={logOut}><CgLogOut/>&nbsp;Kijelentkezés</a>
    );
}

export default Logout;