import { apiBase } from '../util/constants';

export async function checkIfLoggedIn() {
  const settings = {
    credentials: 'include',
  };
  try {
    const response = await fetch(`${apiBase}/users/checkLogin`, settings);
    const resp = await response.json();
    // console.log(resp);
    return (resp);
  } catch (e) {
      return e;
  }    
}

export async function logoutUser() {
  const settings = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  try {
      const fetchResponse = await fetch(`${apiBase}/logout`, settings);
      const resp = await fetchResponse.json();
      return resp;
  } catch (e) {
      return e;
  }    
}

export async function loginUser(data) {
  const settings = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };
  try {
      const fetchResponse = await fetch(`${apiBase}/login`, settings);
      const resp = await fetchResponse.json();
      return resp;
  } catch (e) {
      return e;
  }    
}

export async function registerUser(data) {
  const settings = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };
  try {
      const fetchResponse = await fetch(`${apiBase}/register`, settings);
      const resp = await fetchResponse.json();
      return resp;
  } catch (e) {
      return e;
  }    
}

export async function updatePwd(data) {
  const settings = {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };
  try {
      const fetchResponse = await fetch(`${apiBase}/users/setPassword`, settings);
      const resp = await fetchResponse.json();
      return resp;
  } catch (e) {
      return e;
  }    
}

// CHECK VERIFICATION LINK VALIDITY

export async function linkValidityCheck(userID, key) {
  try {
    const response = await fetch(`${apiBase}/register/${userID}/${key}`);
    const resp = await response.json();
    // console.log(resp);
    return (resp.state == "SUCCESS");
  } catch (e) {
      return e;
  }    
}

export async function delValidationRequest(data) {
  const settings = {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };
  try {
      const fetchResponse = await fetch(`${apiBase}/users/delValidationReq`, settings);
      const resp = await fetchResponse.json();
      return resp;
  } catch (e) {
      return e;
  }    
}

/*

export async function fetchQuizById(quizId) {
  const response = await fetch(`${apiBase}/quizes/${quizId}`);
  const quiz = await response.json();
  return quiz;
}

export async function createQuiz(quiz) {
  await fetch(`${apiBase}/quizes`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(quiz),
  });
}

*/

  // BASIC DATA UPDATE

  // export async function updateUserBasicData(formationId, userRoleName, userData, userDataCP, userDataLeader) {
  //   const settings = {
  //     method: 'PUT',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({formationId, userRoleName, userData, userDataCP, userDataLeader}),
  //     credentials: 'include',
  //   };
  //   try {
  //       const fetchResponse = await fetch(`${apiBase}/loggedinUsers`, settings);
  //       const resp = await fetchResponse.json();
  //       return resp;
  //   } catch (e) {
  //       return e;
  //   }   
  // }

  export async function updateUserBasicData(formationId, userRoleName, userData, entityName) {
    const settings = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({formationId, userRoleName, userData, entityName}),
      credentials: 'include',
    };
    try {
        console.log('HI!');
        const fetchResponse = await fetch(`${apiBase}/loggedinUsers`, settings);
        const resp = await fetchResponse.json();
        return resp;
    } catch (e) {
        return e;
    }   
  }


// FOR ADMIN ROLE

export async function registerAdmin(data) {
  const settings = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  };
  try {
      const fetchResponse = await fetch(`${apiBase}/register/admin`, settings);
      const resp = await fetchResponse.json();
      return resp;
  } catch (e) {
      return e;
  }    
}