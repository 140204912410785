import FormInput from "./FormInput";
import MyDropdown from "./MyDropdown";
import { initialUserDValidity, initialUserData } from "../../../util/initialFormData";
import React, { Component } from 'react';

class UserData extends Component {
    constructor(props) {
      super(props);
  
      // props: userType, values, onValuesChange
      this.props = props;
      // console.log('Values from parent: ', props.values, ' ', props.userType);

      this.state = {
        formValidity: initialUserDValidity(this.props.userType)
      }
    }

    formValidityChange = (key, valValue) => {
      this.setState((prevState) => ({
          ...prevState,
          formValidity: {
              ...prevState.formValidity,
              [key]: valValue
          }
      }));
    }

    userDataChange = (e) => {
      const { name, value, type, checked } = e.target;
      const newValue = type === "checkbox" ? checked : value;

      this.props.onValuesChange({
            ...this.props.values,
            [name]: newValue,
          }, this.props.userType);

      console.log('From child: ', name, 'field was changed!');
    };
  
    render() {
        const input_fields = [
            {
                id: `lastName${this.props.userType}`,
                name: "lastName",
                type: "text",
                // placeholder: "Vezetéknév",
                errorMessage: "Hibás vezetéknév formátum!",
                label: "Vezetéknév",
                regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
                // match: null,
                required: true,
            },
            {
                id: `firstName${this.props.userType}`,
                name: "firstName",
                type: "text",
                // placeholder: "Keresztnév",
                errorMessage: "Hibás keresztnév formátum!",
                label: "Keresztnév",
                regex: "^[A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*([ \.\-][A-Z\u00C0-\u00DC][a-z\u00E0-\u00FC]*)*$",
                // match: null,
                required: true,
            },
            {
                id: `email${this.props.userType}`,
                name: "email",
                type: "text",
                // placeholder: "E-mail cím",
                errorMessage: "Nem megfelelő e-mail cím formátum! Példa: pelda@valami.ro",
                label: "E-mail cím",
                regex: "^[\\\w\\.\\-]{2,20}@[\\\w\\.\\-]{2,20}\\.[\\\w\\-]{2,4}$",
                // match: null,
                required: true,
            },
            {
                id: `telNum${this.props.userType}`,
                name: "telNum",
                type: "text",
                // placeholder: "Telefonszám",
                errorMessage: "Nem megfelelő telefonszám formátum!",
                label: "Telefonszám",
                regex: "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$",
                // match: null,
                required: true,
            },
        ];
  
        return (
          <>
            <form className="user-data-page">
              {input_fields.map((input) => (
                  <FormInput
                    key={input.id}
                    {...input}
                    value={this.props.values[input.name]}
                    onChange={this.userDataChange}
                    validityValue={this.state.formValidity[input.id]}
                    validityCheck={this.formValidityChange}
                  />
              ))}
        
                <div className="full-width-group">
                  {this.props.userType !== 'Leader' ? (
                    <div className="formInput">
                      <label htmlFor="selectedRole">
                        Titulus<span style={{color:'red'}}>*</span>
                      </label>
                      <MyDropdown
                        name="selectedRole"
                        id={`selectedRole${this.props.userType}`}
                        value={this.props.values.selectedRole}
                        onChange={this.userDataChange}
                      />
                    </div>
                  ) : null}
                </div>
          
                  {this.props.userType !== 'CP' ? (
                    <div className="formInput">
                      <label htmlFor="contactPerson">Ő a kapcsolattartó személy?</label>
                      <input
                        type="checkbox"
                        id={`contactPerson${this.props.userType}`}
                        name="contactPerson"
                        checked={this.props.values.contactPerson}
                        onChange={this.userDataChange}
                      />
                    </div>
                  ) : null}

            </form> 
        </>
        );
    }
}
      
    UserData.defaultProps = {
        userType: "",
    };

    export default UserData;