import React, { createContext, useState, useEffect } from "react";
import { checkIfLoggedIn } from "../service/user";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);

    useEffect(async () => {
        console.log('Currently logged in: ');
        console.log(auth);
    
        const resp = await checkIfLoggedIn();
        // console.log(resp);
        if (resp.state === 'SUCCESS') {
            if (resp.mess.isLoggedIn && !auth) {
                setAuth(resp.mess.loggedinData);
            }
        }
      }, [auth]);

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
            {/* {console.log(`Hello from context: ${auth.userID}`)} */}
        </AuthContext.Provider>
    )
}

export default AuthContext;