import React, { Component } from "react";
import {getAllFormationRoles} from '../../../service/formation';

class MyDropdown extends Component {
    constructor(props) {
        super(props);
        //console.log('Hello props', this.props);
        this.state = {
            roles: [],
        }
    }

    async componentDidMount() {
        //console.log('I am now in the DOM');
        const rolesResp = await getAllFormationRoles();
        // console.log(`I got: ${rolesResp.mess[0]}`);
        if (rolesResp.state == 'SUCCESS') {
            this.setState({roles: rolesResp.mess});
            // console.log(this.state.roles);
        }
        else
            // check if backend is running
            console.log(rolesResp.mess);

        // in case of using the hardcoded list
        //this.setState({ roles: this.props.roles });
    }

    render() {
        return (
            <>
                <select value={this.props.value} name={this.props.name} id={this.props.id}
                onChange={this.props.onChange} required={true} className={this.props.className}>
                    <option value="" disabled selected>Válassza ki a megfelelőt...</option>
                    {this.state.roles.map((op) => (
                        <option key={op.id_frole} value={op.id_frole}>{op.name_frole}</option>
                    ))}
                    {/* <option key="egyeb" value="egyeb">egyéb</option> TODO: IRJA BE */}
                </select>
            </>
        );
    }
}

export default MyDropdown;