import React, { useEffect, useState } from "react";
import "./FormationPageUpdate.css";

import { apiBase } from '../../../util/constants';
import { deleteFormationImg } from "../../../service/file";

import { AiOutlineDelete } from "react-icons/ai";
import { RxEyeOpen } from "react-icons/rx";
import toast from 'react-hot-toast';
import FsLightbox from 'fslightbox-react';

function FormationPageImgs(props) {
    const {files, onChangeNotify} = props;
    // const [localFiles, setLocalFiles] = useState([]);

    // useEffect( () => {
    //     setLocalFiles(files);
    // }, [files]);

    // Img viewer
    // To open the lightbox change the value of the "toggler" prop.
	const [toggler, setToggler] = useState(false);
    const [productIndex, setProductIndex] = useState(0);

    let productsImages = files.length > 0 ? files.map((oneFile, index) => (
        <img key={index} src={`${apiBase}/uploads/formations/${oneFile.new_img_name}`} />
    )) : [];

    const deleteImgById = async (imgId, imgName) => {
        const data = {imgName};
        const respDelImg = await deleteFormationImg(imgId, data);
        // console.log(respDelImg);
        if (respDelImg.state == 'SUCCESS') {
            toast.success("A kép törlése sikeres!");

            // delete from state
            let newFiles = files.filter(item => item.id_fimg !== imgId);
            // setLocalFiles(newFiles);

            // notify the upper component about the change
            onChangeNotify(newFiles);

            productsImages = files.length > 0 ? files.map((oneFile, index) => (
                <img key={index} src={`${apiBase}/uploads/formations/${oneFile.new_img_name}`} />
            )) : [];

        } else 
            toast.error("A képet nem sikerült eltávolítani.");
    };

    return (
        <>
            {files.length > 0 ?
                <div className='uploaded-imgs'>
                    {files.map((oneFile, index) => (
                        <div key={index} className='uploaded-img-item'>
                            <img src={`${apiBase}/uploads/formations/${oneFile.new_img_name}`} />
                            <div className='action-wrapper'>
                                <AiOutlineDelete onClick={() => deleteImgById(oneFile.id_fimg, oneFile.new_img_name)} style={{fontSize: '20px', cursor: "pointer"}}/>
                                {/* onClick={deleteImgById(oneFile.id_fimg)} */}
                                <RxEyeOpen onClick={() => {
                                    setProductIndex(index);
                                    setToggler(!toggler);
                                }} style={{fontSize: '20px', cursor: "pointer"}}/>
                            </div>
                        </div>
                    ))}
                </div> 
                : <p>Nem található feltöltött kép.</p>
            }

            <FsLightbox
				toggler={toggler}
				// sources={[
				// 	'https://i.imgur.com/fsyrScY.jpg',
				// 	'https://www.youtube.com/watch?v=3nQNiWdeH2Q',
				// 	'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
				// ]}
                // sources={
                //     files.length > 0 ? files.map(oneFile => (
                //         <img key={oneFile.id_fimg} src={`${apiBase}/uploads/formations/${oneFile.new_img_name}`} />
                //     )) : []
                // }
                sources={productsImages}
                sourceIndex={productIndex}
			/>
        </>
    );
};

export default FormationPageImgs;